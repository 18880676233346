// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import KotHistory from './Pages/KotHistory/KotHistory';
import Reserve from './Pages/Reserve/Reserve';
import OrderHistory from './Pages/Order/OrderHistory';
import Login from './Pages/Login/Login';
import EditLayout from './Pages/EditLayout/EditLayout';
import EditCategories from './Pages/EditMenu/EditCategories';
import EditMenuData from './Pages/EditMenu/EditMenuData';
import Health from './Pages/Health';
import OrderIndex from './Pages/Order/OrderIndex';
import EditUsers from './Pages/EditUsers/EditUsers';
import DashBoard from './Pages/Admin/DashBoard/DashBoard';
import Manage from './Pages/Admin/Manage/Manage';
import ProductDetails from './Pages/Admin/ProductDetails/ProductDetails';
import ProductForm from './Pages/Admin/Manage/ProductForm/productform';
import EditManager from './Pages/Admin/Manage/EditManager';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import useInactivityTimer from './hooks/useInactivityTimer';
import BotHistory from './Pages/BotHistory/BotHistory';
import CustomerLogin from './Pages/Login/CustomerLogin';
import CustomerOrderHistory from './Pages/Order/CustomerOrderHistory';

const theme = createTheme();

function App() {
  const [sessionId, setSessionId] = useState(sessionStorage.getItem('sessionId'));
  const handleLogin = (newSessionId, role) => {
      setSessionId(newSessionId);
      sessionStorage.setItem('sessionId', newSessionId);
  };
  const handleLogout = () => {
    setSessionId(null);
    sessionStorage.removeItem('sessionId');
    sessionStorage.clear(); // Optionally clear all sessionStorage
    Navigate("/");
  };

  useEffect(() => {
    if(sessionStorage?.getItem('Role') !== 'customer' && sessionStorage?.getItem('Role') !== 'user' && sessionStorage?.getItem('Role') !== 'manager')
      {
        const interval = setInterval(() => {
          const lastActivity = sessionStorage.getItem('lastActivity');
          if (lastActivity && new Date().getTime() - lastActivity > 30 * 60 * 1000) {
              handleLogout();
          }
      }, 1000 * 60); // check every minute

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
      const updateLastActivity = () => {
          sessionStorage.setItem('lastActivity', new Date().getTime());
      };

      window.addEventListener('mousemove', updateLastActivity);
      window.addEventListener('keydown', updateLastActivity);

      return () => {
          window.removeEventListener('mousemove', updateLastActivity);
          window.removeEventListener('keydown', updateLastActivity);
      };
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/health" element={<Health />} />
            <Route path="/" element={sessionStorage.getItem('sessionId') && sessionStorage.getItem('Role') === 'admin'? <Navigate to="/dashboard" /> : sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager') ?<Navigate to="/reserve"/>:(sessionStorage.getItem('Role') === 'chef')?<Navigate to="/kotHistory"/>  : (sessionStorage.getItem('Role') === 'bartender')?<Navigate to="/botHistory"/>: <Login onLogin={handleLogin} />} />
            <Route path="/customer" element={sessionStorage.getItem('sessionId') && sessionStorage.getItem('Role') === 'customer'? <Navigate to="/customerOrder" /> : <CustomerLogin onLogin={handleLogin}/>}/>
            <Route path="/kotHistory" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'chef')||sessionStorage.getItem('Role') === 'admin' ? <KotHistory /> : <Navigate to="/" />} />
            <Route path="/botHistory" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'bartender')||sessionStorage.getItem('Role') === 'admin' ? <BotHistory/> : <Navigate to="/" />} />
            <Route path="/reserve" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager')||sessionStorage.getItem('Role') === 'admin' ? <Reserve /> : <Navigate to="/" />} />
            <Route path="/order" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager')||sessionStorage.getItem('Role') === 'admin' ? <OrderIndex /> : <Navigate to="/" />} />
            <Route path="/orderHistory" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'user' || sessionStorage.getItem('Role') === 'manager')||sessionStorage.getItem('Role') === 'admin'? <OrderHistory /> : <Navigate to="/" />} />
            <Route path="/editLayout" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'admin')? <EditLayout /> : <Navigate to="/" />} />
            <Route path="/editMenu" element={sessionStorage.getItem('sessionId') &&  (sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'admin')? <EditCategories /> : <Navigate to="/" />} />
            <Route path="/editMenuData" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'admin')? <EditMenuData /> : <Navigate to="/" />} />
            <Route path="/editUsers" element={sessionStorage.getItem('sessionId') &&  (sessionStorage.getItem('Role') === 'manager'||sessionStorage.getItem('Role') === 'admin')? <EditUsers /> : <Navigate to="/" />} />
            <Route path="/dashboard" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <DashBoard /> : <Navigate to="/" />} />
            <Route path="/manage" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <Manage /> : <Navigate to="/" />} />
            <Route path="/productDetails" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <ProductDetails /> : <Navigate to="/" />} />
            <Route path="/addProduct" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <ProductForm /> : <Navigate to="/" />} />
            <Route path="/editProduct" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <ProductForm /> : <Navigate to="/" />} />
            <Route path="/manageProduct" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <ProductForm /> : <Navigate to="/" />} />
            <Route path="/editManager" element={sessionStorage.getItem('sessionId') &&  sessionStorage.getItem('Role') === 'admin'? <EditManager /> : <Navigate to="/" />} />
            <Route path="/customerOrder" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'customer'||sessionStorage.getItem('Role') === 'admin') ? <OrderIndex /> : <Navigate to="/customer" />} />
            <Route path="/customerOrderHistory" element={sessionStorage.getItem('sessionId') && (sessionStorage.getItem('Role') === 'customer'||sessionStorage.getItem('Role') === 'admin') ? <CustomerOrderHistory /> : <Navigate to="/customer" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
