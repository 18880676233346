import { Box, Button, FormControl, Grid, Input, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useRef,useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../components/Navbar";
import UserDetails from "../../components/UserDetails";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveIcon from '../../assets/Remove.png';
import { OrderHistoryData, menudata } from "../Order/OrderActions/OrderActionApi";
import { TableData } from "../Reserve/ReserveActions/ReserveActionApi";
import { kothistory } from "../KotHistory/KotHistoryActions/KotHistoryActionApi";
import { CategoryList, EditMenuData1 } from "./EditMenuActions/EditMenuApi";
import {ReactComponent as Upload} from '../../assets/Upload.svg';
import MessageBox from "../../components/MessageBox";
import loader from '../../assets/EditLoader.gif';

const EditMenuData=()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = sessionStorage?.getItem("Token");
    const product_id = sessionStorage?.getItem("ProductId");
    const email = sessionStorage?.getItem("Email");
    const role = sessionStorage?.getItem("Role");

    const loadingData = useSelector((state) => state.editmenu.EditResponseListDataLoading);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Category = searchParams.get('Category');
    const CategoryType = searchParams.get('CategoryType');
    let counter = 0;
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [RemoveCategoryItem,setRemoveCategoryItem] = useState({});
    const [newRecord, setNewRecord] = useState({});
    const [CategoryItemData,setCategoryItemData] = useState([]);
    const [selectedButton, setSelectedButton] = useState("Veg");
    const fileInputRef = useRef(null);
    const [Name,setName]=useState();
    const [Type,setType]=useState('');
    const [Information,setInformation]=useState('');
    const [Ingredients,setIngredients]=useState('');
    const [PrepTime,setPrepTime]= useState('');
    const [Price,setPrice]= useState('');
    const [imageData, setImageData] = useState(null);

    const ListCategory = useSelector((state) => state.editmenu.ListResponseData);

    const handleCloseMessageBox = () => {
        setShowMessageBox(false);
      };
    
    const handleNameChange = (event)=>{
        setName(event.target.value);
    }
    const handleInformationChange=(event)=>{
        setInformation(event.target.value);
    }
    const handleIngredientsChange=(event)=>{
        setIngredients(event.target.value);
    }
    const handlePrepTimeChange=(event)=>{
        try{
            let value = parseInt(event.target.value);
            if(value > 120)
            {
                setMessage("Prep-Time can't be greater than 120");
                setMessageType('error');
                setShowMessageBox(true);
                event.target.focus();
                setPrepTime('');
                return
            }
            if(value<0)
            {
                setMessage("Prep-Time can't be smaller than 0");
                setMessageType('error');
                setShowMessageBox(true);
                event.target.focus();
                setPrepTime('');
                return
            }
            setPrepTime(value);
        }
        catch(error){
            setMessage('Please enter only number');
            setMessageType('error');
            setShowMessageBox(true);
            event.target.focus();
            setPrepTime('');
        }
    }
    const handlePriceChange=(event)=>{
        try{
            let value = parseInt(event.target.value);
            if(value<0)
            {
                setMessage("Price can't be smaller than 0");
                setMessageType('error');
                setShowMessageBox(true);
                event.target.focus();
                setPrice('');
                return
            }
            setPrice(value);
        }
        catch(error){
            setMessage('Please enter only number');
            setMessageType('error');
            setShowMessageBox(true);
            event.target.focus();
            setPrice('');
        }
       
    }

    const saveUpdate=async()=>{
        if (newRecord.hasOwnProperty(Category)) {
            if(newRecord[Category].length===0)
            {
                await dispatch(EditMenuData1(email,product_id,token,{},RemoveCategoryItem));
                setNewRecord({})
                setRemoveCategoryItem({})
                return   
            }
        }
        await dispatch(EditMenuData1(email,product_id,token,newRecord,RemoveCategoryItem));
        setNewRecord({});
        setRemoveCategoryItem({});
    }
    
    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId === selectedButton ? buttonId : buttonId);
      };
    
    const handleImageUpload = () =>{
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check if the file is an image
            if (!file.type.startsWith('image/')) {
            console.error('File is not an image.');
            return;
            }

            const reader = new FileReader();
            reader.onload = () => {
            const base64String = reader.result;
            setImageData(base64String);
            };
            reader.readAsDataURL(file);
        }
      };
    
       const handleType = (event) => {
        setType(event.target.value);
    };

    const MainData = useSelector((state)=> state.order.MainMenuResponseData);

    useEffect(() => {
        if(!token ||token === "undefined" || token === "null" || token === "none")
        {
            navigate("/")
        }
        else
        {
            if(role !== "manager")
            {
                navigate("/reserve")
            }
            if(Category && Category !== '' && Category !== 'none')
            {    
                dispatch(menudata(product_id,email,token))
                if(MainData.length === 0 )
                {
                    dispatch(TableData(product_id, email, token));
                    dispatch(OrderHistoryData(email, product_id, token));
                    dispatch(kothistory(email, product_id, token));
                    dispatch(CategoryList(email,product_id,token));
                }
            }
            else{
               navigate("/editMenu")
            }
        }  
    },[dispatch]);


    useEffect(() => {
        if (MainData && Category) {
            if(CategoryType==='food_and_beverages')
            {
                if (ListCategory[0]?.[CategoryType].includes(Category)) {
                    setType('food');
                } else {
                    navigate("/editMenu");
                }
            }
            else if(CategoryType==='alcoholic_beverages')
            {
                if (ListCategory[1]?.[CategoryType].includes(Category)) {
                    setType('alcoholic_beverage');
                } else {
                    navigate("/editMenu");
                }
            }
            else
            {
                 navigate("/editMenu");
            }
               
            setNewRecord({});
            setRemoveCategoryItem({});
          const data = Object.keys(MainData).flatMap((type)=>
          Object.keys(MainData[type])
            .flatMap((item) =>
              MainData[type][item][Category]
                ? Object.keys(MainData[type][item][Category]).map((item1) => ({
                    id: item1,
                    name: MainData[type][item][Category][item1]?.item_name,
                    ingredients: MainData[type][item][Category][item1]?.item_ingredients,
                    description: MainData[type][item][Category][item1]?.item_description,
                    image: MainData[type][item][Category][item1]?.item_image,
                    prep_time: MainData[type][item][Category][item1]?.item_prep_time_minutes,
                    veg_or_nonveg: item,
                    food_or_beverage: type,
                    amount: MainData[type][item][Category][item1]?.amount,
                  }))
                : []
                )
            );
          setCategoryItemData(data);
          
        }
      }, [MainData, Category]);


    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };
    
    const handleRemoveItem = (category, itemId,name) => {
        setCategoryItemData((prevData) => prevData.filter((item) => item.id !== itemId));
        const newRecordCopy = { ...newRecord };
        if (newRecordCopy.hasOwnProperty(category)) {
            // Find the index of the item with the given itemName
            const index = newRecordCopy[category].findIndex(
              (item) => item.item_name === name
            );
            // If the item exists, remove it from the array
            if (index !== -1) {
              newRecordCopy[category].splice(index, 1);
              setNewRecord(newRecordCopy);
              return; // Return true if the item was deleted
            } else {
                setRemoveCategoryItem(prevState => {
                    return {
                      ...prevState,
                      [category]: {
                        ...prevState[category],
                        ["item_id"]: prevState[category]?.["item_id"]
                          ? [...prevState[category]["item_id"], itemId]
                          : [itemId]
                      }
                    };
                  });
            }
          } else {
            setRemoveCategoryItem(prevState => {
                return {
                  ...prevState,
                  [category]: {
                    ...prevState[category],
                    ["item_id"]: prevState[category]?.["item_id"]
                      ? [...prevState[category]["item_id"], itemId]
                      : [itemId]
                  }
                };
              });
          }
    };
    const convertPrepTimeToHHMMSS = (prepTimeInMinutes) => {
        const hours = Math.floor(prepTimeInMinutes / 60);
        const minutes = prepTimeInMinutes % 60;
        const seconds = 0; // Assuming there are no seconds in the prep time
      
        // Format hours, minutes, and seconds to ensure two digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      };
    const additem = async()=>{
        if(Name==='')
        {
            setMessage('Name Field is Empty');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        if(Information==='')
        {
            setMessage('Information Field is Empty');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        if(Ingredients==='')
        {
            setMessage('Ingredients Field is Empty');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        if(PrepTime==='')
        {
            setMessage('Prep-Time Field is Empty');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        if(Price==='')
        {
            setMessage('Price Field is Empty');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        if(imageData===null)
        {
            setMessage('Upload image');
            setMessageType('error');
            setShowMessageBox(true);
            return
        }
        const newData = {
            id: CategoryItemData.length > 0 ? parseInt(CategoryItemData[CategoryItemData.length - 1].id) + 1 : 1, 
            name: Name, 
            ingredients: Ingredients, 
            description: Information, 
            image: imageData, 
            prep_time: PrepTime, 
            veg_or_nonveg: selectedButton,
            food_or_beverage: Type, 
            amount: Price, 
          };
          
          // Add the new data to the existing data
          const updatedData = [...CategoryItemData, newData];
          
          // Update the state with the updated data
          setCategoryItemData(updatedData);

          const newItem = {
            item_name: Name.toLocaleLowerCase(),
            item_information: Information,
            item_ingredients: Ingredients,
            item_preperation_time: convertPrepTimeToHHMMSS(PrepTime),
            item_amount: parseFloat(Price.toString()),
            item_image: imageData,
            food_or_beverage: Type,
            veg_or_nonveg: selectedButton.toLocaleLowerCase(),
          };
          const newRecordCopy = { ...newRecord };
          if (newRecordCopy.hasOwnProperty(Category)) {
            newRecordCopy[Category].push(newItem);
          } else {
            newRecordCopy[Category] = [newItem];
          }
          setNewRecord(newRecordCopy);
          setName('');
          setInformation('');
          setIngredients('');
          setImageData(null);
          setPrepTime('');
          setPrice('');
    }

    const renderMenuItems = () => {
        if (CategoryType === 'food_and_beverages') {
            return [
                <MenuItem key='food' value='food' sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>Food</MenuItem>,
                <MenuItem key='beverage' value='beverage' sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>Beverage</MenuItem>
            ];
        } else {
            return <MenuItem value='alcoholic_beverage' sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>Alcoholic</MenuItem>;
        }
    };

    return(
    <>
        <Grid container spacing={1} sx={{filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto",height:"100vh"}}>
            {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="editMenu" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"99vh"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{marginTop:"1.5%"}}>
                        <Paper elevation={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                    sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '800',
                                    marginLeft: "1.5%",
                                    paddingTop: "1.5%",
                                    paddingBottom: "1.5%"
                                    }}
                                    variant="h4"
                                    noWrap
                                    component="div"
                                >
                                    <span style={{color:"#0F1419"}}>Category :  </span><span style={{color:"#0093FB"}}>{toTitleCase(Category)}</span>
                                </Typography>
                            </Grid>
                        </Paper>
                            <Grid item xs={12} md={12} lg={12} sx={{marginTop:"2.5%"}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Paper elevation={3}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <Typography
                                                        sx={{
                                                        fontFamily: 'Poppins',
                                                        fontWeight: '400',
                                                        color: '#5E5E5E',
                                                        marginLeft: "4.5%",
                                                        paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                    >
                                                        Remove Item
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} sx={{marginTop:"5.5%",overflowY:"auto",height:"29rem"}}>
                                                    {CategoryItemData && Object?.keys(CategoryItemData).map((item)=>{
                                                        counter = counter+1;
                                                        return(
                                                        <Grid container spacing={1} key={`${CategoryItemData[item].id}-${counter}`} sx={{
                                                            backgroundColor:"#F2F2F2",
                                                            marginLeft:"2%",
                                                            marginBottom:"6%",
                                                            border:" 1px solid #000000",
                                                            borderRadius: 2,
                                                            width:"95%",
                                                        }}
                                                        >
                                                            <Grid item xs={8} md={8} lg={8} sx={{
                                                                paddingBottom: "2%"
                                                            }}
                                                            
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: '400',
                                                                        color: '#4A4B4C',
                                                                        paddingLeft:"5.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                    className="carttext"
                                                                >
                                                                    {toTitleCase(CategoryItemData[item].name)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} md={4} lg={4} sx={{display:"flex",justifyContent:"flex-end",paddingBottom: "2%"}}>
                                                                <img src={RemoveIcon} style={{width: "20%",paddingRight:"5.5%"}} className='im-delete' alt="Remove Icon" onClick={()=>handleRemoveItem(Category,CategoryItemData[item].id,CategoryItemData[item].name)}/>
                                                            </Grid>
                                                        </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8}>
                                       <Grid container spacing={1}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Paper elevation={3}>
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#5E5E5E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h5"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Add Item in: 
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7} sx={{marginTop: "0.5%"}}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={5.8} sx={{ marginBottom: "1.5%" }}>
                                                                        <Button
                                                                        onClick={() => handleButtonClick('Veg')}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width:'100%',
                                                                            color: selectedButton === 'Veg' ? '#fff' : '#5E5E5E',
                                                                            backgroundColor: selectedButton === 'Veg' ? '#058E6E' : 'transparent',
                                                                            border: '1px solid #000000',
                                                                            '&:hover': {
                                                                                backgroundColor: selectedButton === 'Veg' ? '#058E6E' : '#058E6E',
                                                                                border: '1px solid #000000',
                                                                                color:"#ffff",
                                                                            },
                                                                        }}
                                                                        >
                                                                        Veg
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={5.8}>
                                                                        <Button
                                                                        onClick={() => handleButtonClick('Non Veg')}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width:'100%',
                                                                            color: selectedButton === 'Non Veg' ? '#fff' : '#5E5E5E',
                                                                            backgroundColor: selectedButton === 'Non Veg' ? '#058E6E' : 'transparent',
                                                                            border: '1px solid #000000',
                                                                            '&:hover': {
                                                                                backgroundColor: selectedButton === 'Non Veg' ? '#058E6E' : '#058E6E',
                                                                                border: '1px solid #000000',
                                                                                color:"#ffff",
                                                                            },
                                                                        }}
                                                                        >
                                                                        Non Veg
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginTop:"2.5%" ,marginBottom:"1.5%"}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Name :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                            <TextField
                                                                value={Name}
                                                                onChange={handleNameChange}
                                                                //error={!!error}
                                                                //helperText={error}
                                                                sx={{
                                                                    width:"90%",
                                                                    textAlign: "center",
                                                                    justifyContent:"center",
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                          borderColor: '#5E5E5E', // Default border color
                                                                        },
                                                                        '&:hover fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color on hover
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color when focused
                                                                        },
                                                                      },
                                                                      '& .MuiInputBase-root': {
                                                                        height: 10, // Adjust the height here
                                                                      },
                                                                      '& .MuiInputBase-input': {
                                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                                      },
                                                                    }}
                                                                    InputProps={{
                                                                      style: {
                                                                        height: '40px',
                                                                        color: "#5E5E5E",
                                                                      },
                                                                }}
                                                            />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginBottom:"1.5%"}}>
                                                    <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Information :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                            <TextField
                                                                value={Information}
                                                                onChange={handleInformationChange}
                                                                //error={!!error}
                                                                //helperText={error}
                                                                sx={{
                                                                    width:"90%",
                                                                    textAlign: "center",
                                                                    justifyContent:"center",
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                          borderColor: '#5E5E5E', // Default border color
                                                                        },
                                                                        '&:hover fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color on hover
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color when focused
                                                                        },
                                                                      },
                                                                      '& .MuiInputBase-root': {
                                                                        height: 10, // Adjust the height here
                                                                      },
                                                                      '& .MuiInputBase-input': {
                                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                                      },
                                                                    }}
                                                                    InputProps={{
                                                                      style: {
                                                                        height: '40px',
                                                                        color: "#5E5E5E",
                                                                      },
                                                                }}
                                                            />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginBottom:"1.5%"}}>
                                                    <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Ingredients :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                            <TextField
                                                                value={Ingredients}
                                                                onChange={handleIngredientsChange}
                                                                //error={!!error}
                                                                //helperText={error}
                                                                sx={{
                                                                    width:"90%",
                                                                    textAlign: "center",
                                                                    justifyContent:"center",
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                          borderColor: '#5E5E5E', // Default border color
                                                                        },
                                                                        '&:hover fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color on hover
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                          borderColor: '#5E5E5E', // Border color when focused
                                                                        },
                                                                      },
                                                                      '& .MuiInputBase-root': {
                                                                        height: 10, // Adjust the height here
                                                                      },
                                                                      '& .MuiInputBase-input': {
                                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                                      },
                                                                    }}
                                                                    InputProps={{
                                                                      style: {
                                                                        height: '40px',
                                                                        color: "#5E5E5E",
                                                                      },
                                                                }}
                                                            />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginTop:"2.5%" ,marginBottom:"1.5%"}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Type :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                                                                <Select
                                                                    labelId="demo-customized-select-label"
                                                                    id="demo-customized-select"
                                                                    value={Type}
                                                                    onChange={handleType}
                                                                    sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"90%"}}
                                                                >
                                                                    {renderMenuItems()}
                                                                </Select>
                                                            </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginBottom:"1.5%"}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Preparation Time :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={3.8}>
                                                                        <TextField
                                                                            value={PrepTime}
                                                                            onChange={handlePrepTimeChange}
                                                                            //error={!!error}
                                                                            //helperText={error}
                                                                            sx={{
                                                                                width:"90%",
                                                                                textAlign: "center",
                                                                                justifyContent:"center",
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                    borderColor: '#5E5E5E', // Default border color
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                    borderColor: '#5E5E5E', // Border color on hover
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                    borderColor: '#5E5E5E', // Border color when focused
                                                                                    },
                                                                                },
                                                                                '& .MuiInputBase-root': {
                                                                                    height: 10, // Adjust the height here
                                                                                },
                                                                                '& .MuiInputBase-input': {
                                                                                    padding: '15px 10px', // Adjust the padding inside the input
                                                                                },
                                                                                }}
                                                                                InputProps={{
                                                                                style: {
                                                                                    height: '40px',
                                                                                    color: "#5E5E5E",
                                                                                },
                                                                                inputProps: {
                                                                                    type: 'number', // Specify input type as number
                                                                                    min: 0, // Optional: Specify minimum allowed value
                                                                                    max: 100,
                                                                                  },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3.8}>
                                                                        <TextField
                                                                            value="mins"
                                                                            //onChange={handleInputChange}
                                                                            //error={!!error}
                                                                            //helperText={error}
                                                                            sx={{
                                                                                width:"90%",
                                                                                textAlign: "center",
                                                                                justifyContent:"center",
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                    borderColor: '#5E5E5E', // Default border color
                                                                                    },
                                                                                },
                                                                                '& .MuiInputBase-root': {
                                                                                    height: 10, // Adjust the height here
                                                                                    color: "#5E5E5E",
                                                                                    borderColor: '#5E5E5E',
                                                                                },
                                                                                '& .MuiInputBase-input': {
                                                                                    textAlign:'center',
                                                                                    padding: '15px 10px', // Adjust the padding inside the input
                                                                                    color: "#5E5E5E", // Text color
                                                                                },
                                                                                }}
                                                                                InputProps={{
                                                                                style: {
                                                                                    height: '40px',
                                                                                    color: "#5E5E5E",
                                                                                },
                                                                                disabled: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{marginBottom:"1.5%"}}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    Price : 
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={3.8}>
                                                                        <TextField
                                                                            value="RS"
                                                                            //onChange={handleInputChange}
                                                                            //error={!!error}
                                                                            //helperText={error}
                                                                            sx={{
                                                                                width:"90%",
                                                                                textAlign: "center",
                                                                                justifyContent:"center",
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                    borderColor: '#5E5E5E', // Default border color
                                                                                    },
                                                                                },
                                                                                '& .MuiInputBase-root': {
                                                                                    height: 10, // Adjust the height here
                                                                                    color: "#5E5E5E",
                                                                                    borderColor: '#5E5E5E',
                                                                                },
                                                                                '& .MuiInputBase-input': {
                                                                                    textAlign:'center',
                                                                                    padding: '15px 10px', // Adjust the padding inside the input
                                                                                    color: "#5E5E5E", // Text color
                                                                                },
                                                                                }}
                                                                                InputProps={{
                                                                                style: {
                                                                                    height: '40px',
                                                                                    color: "#5E5E5E",
                                                                                },
                                                                                disabled: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3.8}>
                                                                        <TextField
                                                                            value={Price}
                                                                            onChange={handlePriceChange}
                                                                            //error={!!error}
                                                                            //helperText={error}
                                                                            sx={{
                                                                                width:"90%",
                                                                                textAlign: "center",
                                                                                justifyContent:"center",
                                                                                '& .MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                    borderColor: '#5E5E5E', // Default border color
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                    borderColor: '#5E5E5E', // Border color on hover
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                    borderColor: '#5E5E5E', // Border color when focused
                                                                                    },
                                                                                },
                                                                                '& .MuiInputBase-root': {
                                                                                    height: 10, // Adjust the height here
                                                                                },
                                                                                '& .MuiInputBase-input': {
                                                                                    padding: '15px 10px', // Adjust the padding inside the input
                                                                                },
                                                                                }}
                                                                                InputProps={{
                                                                                style: {
                                                                                    height: '40px',
                                                                                    color: "#5E5E5E",
                                                                                },
                                                                                inputProps: {
                                                                                    type: 'number', // Specify input type as number
                                                                                    min: 0, // Optional: Specify minimum allowed value
                                                                                  },
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3.8}>
                                                                        <input
                                                                            type="file"
                                                                            ref={fileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            onChange={handleFileChange}
                                                                        />
                                                                        <Button
                                                                            onClick={() => handleImageUpload()}
                                                                            variant="outlined"
                                                                            startIcon={<Upload />}
                                                                            sx={{
                                                                                width:'100%',
                                                                                height: '100%',
                                                                                color: '#fff',
                                                                                backgroundColor: imageData===null?'#0093FB':'#F2F2F2',
                                                                                border: '1px solid #0093FB',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#0093FB',
                                                                                    border: '1px solid #0093FB',
                                                                                    color:"#ffff",
                                                                                },
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} sx={{paddingTop:"1.5%",paddingBottom:"2.5%", paddingRight:"2.5%",display:"flex",textAlign:"center",justifyContent:"flex-end",alignItems:"center"}}>
                                                        <Button
                                                            onClick={() => additem()}
                                                            variant="outlined"
                                                            sx={{
                                                                width:'30%',
                                                                color: '#fff' ,
                                                                backgroundColor: '#058E6E' ,
                                                                border: '1px solid #058E6E',
                                                                '&:hover': {
                                                                            backgroundColor: '#14B996',
                                                                            border: '1px solid #14B996',
                                                                            color:"#ffff",
                                                                            },
                                                                }}
                                                        >
                                                            Add Item
                                                        </Button>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12} sx={{height:"4rem",marginTop:"1.5%",marginBottom:"1.5%"}}>
                                                <Button
                                                    onClick={() => saveUpdate()}
                                                    variant="outlined"
                                                    sx={{
                                                        width:'100%',
                                                        height:'100%',
                                                        color: '#fff' ,
                                                        backgroundColor: '#0093FB' ,
                                                        border: '1px solid #0093FB',
                                                        '&:hover': {
                                                                    backgroundColor: '#2AAFFF',
                                                                    border: '1px solid #2AAFFF',
                                                                    color:"#ffff",
                                                                  },
                                                        }}
                                                >
                                                    Save Changes
                                                </Button>              
                                            </Grid>               
                                        </Grid> 
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showMessageBox && (
                <MessageBox
                    message={message}
                    type={messageType}
                    duration={3000}
                    onClose={handleCloseMessageBox}
                />
            )}
        </Grid>
    </>
    )    
}
export default EditMenuData