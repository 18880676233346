import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CompleteKot, kothistory } from "../KotHistory/KotHistoryActions/KotHistoryActionApi";
import { OrderHistoryData, OrderPaymentCompleted, menudata, OrderCompleted, OrderPrintBill, OrderEditBill } from "./OrderActions/OrderActionApi";
import './OrderHistory.css';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { ReactComponent as Prev } from '../../assets/Prev.svg';
import {ReactComponent as Delete} from '../../assets/Trash.svg';
import Navbar from "../../components/Navbar";
import { Box, ButtonBase, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate} from 'react-router-dom';
import { TableData } from "../Reserve/ReserveActions/ReserveActionApi";
import UserDetails from "../../components/UserDetails";
import ElapsedTimeCounter from "../KotHistory/ElapsedTimeCounter";
import { CategoryList } from "../EditMenu/EditMenuActions/EditMenuApi";
import { get_users_data } from "../EditUsers/EditUsersActions/EditUserActions";
import { socket_url } from "../../Redux/constants";
import { useReactToPrint } from 'react-to-print';
import { HeadingTextColor } from "../../components/GlobleColor";
import PrintDialog from "./PrintDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderHistory = () => {
  const dispatch = useDispatch();
  const [Editbill, setEditBill] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const componentRef = useRef();
  const token = sessionStorage?.getItem("Token");
  const email = sessionStorage?.getItem("Email");
  const product_id = sessionStorage?.getItem("ProductId");
  const navigate = useNavigate();
  const orderHistoryState = useSelector((state) => state.order.OrderHistoryData);
  const OrderHistoryLoading = useSelector((state) => state.order.OrderHistoryLoading);
  const OrderHistoryResponse = useSelector((state) => state.order.OrderHistoryResponse);
 
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupBill,setOpenPopupBill] = useState(false);
  const [openPopupEditBill,setOpenPopupEditBill] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [ws, setWs] = useState(null);
 
  useEffect(() => {
    if(!token ||token === "undefined" || token === "null" || token === "none")
      {
          navigate("/")
      }
      else
      {
        dispatch(OrderHistoryData(email, product_id, token));
        if(OrderHistoryResponse.length === 0)
        {
          dispatch(menudata(product_id,email,token));
          dispatch(get_users_data(email,product_id,token));
          dispatch(TableData(product_id, email, token));
          dispatch(kothistory(email, product_id, token));
          dispatch(CategoryList(email,product_id,token));
        }
      }
            // Initialize WebSocket connection
    const socket = new WebSocket(socket_url);
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      try {
        const message = event.data;
      // Handle incoming messages and update state as needed
      if (message === "order_complete" || message === "transfer_table" || message === "payment_complete") {
        dispatch(OrderHistoryData(email, product_id, token)); // Update KOT history on new message
      }
    }
    catch (error) {
      console.error("Error parsing message:", error);
    }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close(); // Clean up WebSocket connection on unmount
    }
  }, [dispatch]);

  useEffect(() => {
    if (OrderHistoryResponse && OrderHistoryResponse.data) {
      setOrderHistoryData(OrderHistoryResponse.data);
    }
  }, [OrderHistoryResponse]);


  const convertDateFormat = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // Format to HH:MM:SS
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;

    return formattedTime;
  };
  const handleRowClick = (orderData) => {
    setPopupData(orderData);
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
    setOpenPopupBill(false);
    setOpenPopupEditBill(false);
    setPopupData(null);
  };
  const returnpage=()=>{
    setOpenPopupEditBill(false);
    setOpenPopup(true);
  }
  const handleOrderPaymentCompleted = async () => {
    try {
      if (popupData && popupData.order_id) {
        await dispatch(OrderPaymentCompleted(popupData.order_id,email,product_id,token));
        handlePopupClose();
        dispatch(OrderHistoryData(email, product_id, token)); // Call kothistory after completing the KOT
        dispatch(TableData(product_id, email, token));
      }
    } catch (error) {
      console.error('Error in order payment :', error);
    }
  };

  const handleOrderCompleted = async () => {
    try {
      if (popupData && popupData.order_id) {
        await dispatch(OrderCompleted(popupData.order_id,email,product_id,token));
        handlePopupClose();
        const response = await dispatch(OrderPrintBill(popupData.order_id, email, product_id, token));
        setTimeout(() => {
          setPdfUrl(response.data);
          setOpenPopupBill(true);
          setTimeout(() => {
            handlePrint();
            setOpenPopupBill(false);
          }, 500);
        }, 500);
        dispatch(OrderHistoryData(email, product_id, token)); // Call kothistory after completing the KOT
        dispatch(TableData(product_id, email, token));
        setOpenPopupBill(false);
      }
    } catch (error) {
      console.error('Error in order completion :', error);
    }
  };
  const formattingString = (name) => {
    if (name.length > 30) {
      const firstLine = name.substring(0, 8);
      const remaining = name.substring(8);
      return firstLine + "-\n" + formattingString(remaining);
    } else {
      return name;
    }
  };
  const renderItems = () => {
    const validJSON = pdfUrl?.items.replace(/'/g, '"');
    const data = JSON.parse(validJSON);

    return data.map((item) => (
      <tr key={item.name}>
        <td>{formattingString(item.name)}</td>
        <td>₹{item.amount}</td>
        <td>{item.quantity}</td>
        <td>₹{item.total}</td>
      </tr>
    ));
  };
  const handleOrderPrintBill = async () => {
    try {
      if (popupData && popupData.order_id) {
        const response = await dispatch(OrderPrintBill(popupData.order_id, email, product_id, token));
        setPdfUrl(response.data);
        handlePopupClose();
        setOpenPopupBill(true);
        setTimeout(() => {
          handlePrint();
          setOpenPopupBill(false);
        }, 500);
        await dispatch(OrderHistoryData(email, product_id, token));
        await dispatch(TableData(product_id, email, token));
      }
    } catch (error) {
      console.error('Error in order bill print:', error);
    }
  };
  const parseItems = (bill) => {
    const validJSON = bill.items.replace(/'/g, '"');
    const data = JSON.parse(validJSON);
    bill.items = data;
    return bill;
  };
  // Function to recalculate the total and other values
  const recalculateTotals = (updatedItems) => {
    const total = updatedItems.reduce((sum, item) => sum + item.total, 0);
    const sgst = total *(2.5/100);
    const cgst = total *(2.5/100);
    const total_with_tax = total - Editbill.discount + Editbill.round_off+sgst+cgst;
    setEditBill((prevBill) => ({
      ...prevBill,
      items: updatedItems,
      sgst:sgst,
      cgst: cgst,
      total: total,
      total_with_tax: total_with_tax,
    }));
  };
    // Function to handle quantity change
    const updateQuantity = (index, increment) => {
      const updatedItems = Editbill.items
        .map((item, i) => {
          if (i === index) {
            const newQuantity = item.quantity + increment;
            // Only update the item if the new quantity is greater than 0
            if (newQuantity > 0) {
              return {
                ...item,
                quantity: newQuantity,
                total: newQuantity * item.amount,
              };
            }
            return null; // Return null for items to be removed
          }
          return item;
        })
        .filter(item => item !== null); // Filter out null values (i.e., items with quantity 0)
      recalculateTotals(updatedItems);
    };
  // Function to remove an item
  const removeItem = (index) => {
    const updatedItems = Editbill.items.filter((_, i) => i !== index);
    recalculateTotals(updatedItems);
  };
  const handleEditBill=async()=>{
    try {
      if (popupData && popupData.order_id) {
        const response = await dispatch(OrderPrintBill(popupData.order_id, email, product_id, token));
        setEditBill(parseItems(response.data));
        handlePopupClose();
        setOpenPopupEditBill(true);
        await dispatch(OrderHistoryData(email, product_id, token));
        await dispatch(TableData(product_id, email, token));
      }
    } catch (error) {
      console.error('Error in order bill print:', error);
    }
  }
  const handleEditBillData = async()=>{
    await dispatch(OrderEditBill(Editbill,email, product_id, token));
    handlePopupClose();
  }

 const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Order Bill',
    onafterprint : () => {alert('printed Success')},
  });

 

  

  return (
    <Grid container>
      <PrintDialog
        openPopupBill={openPopupBill}
        handlePopupClose={handlePopupClose}
        pdfUrl={pdfUrl}
        convertDateFormat={convertDateFormat}
        formatTime={formatTime}
        renderItems={renderItems}
        ref={componentRef}
      />
    <Grid item xs={12} md={3} lg={2}>
   <Navbar active="orderHistory"/>
    </Grid>
      <Grid item xs={12}md={12} lg={10} className="table-container-1">
      <Grid container spacing={0} sx={{paddingRight:"1.5%",paddingLeft:"1.5%"}}>
          <Grid item xs={12} md={12} lg={12}>
              <UserDetails/>
          </Grid>
      </Grid>
        <div className="table-container">
          {OrderHistoryLoading && <p>Loading...</p>}
          <table className="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Table</th>
              
                <th>Timer</th>
                <th>Total</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {orderHistoryData && orderHistoryData.length > 0 ? (
                orderHistoryData.map((item) => (
                  <tr key={item.order_id} onClick={() => handleRowClick(item)}>
                    <td>{item.order_id}</td>
                    <td>{item.table_id}</td>
                    
                    <td>
        {item.order_complete_time ? "---" : <ElapsedTimeCounter orderInTime={item.order_in_time} withEffects={false} />
      }
      </td>
                    <td>RS {item.grand_total}</td>
                    <td>{item.status || "Not available"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No order history data available</td>
                </tr>
              )}
            </tbody>
          </table>

          <Dialog
            open={openPopup}
            onClose={handlePopupClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <div style={{ position: "relative" }}>
                <IconButton
                  aria-label="close"
                  onClick={handlePopupClose}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>

            {popupData && (
              <DialogContent className="dialog-content">
                {popupData && (
                  <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
  <p style={{ fontFamily: "Poppins", fontWeight: 300, marginRight: "10px" }}>IN TIME: {popupData.order_in_time} </p>
  <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>
    OUT TIME: {popupData.order_complete_time ? popupData.order_complete_time : "---"}
  </p>
</div>

                    <Box sx={{ fontSize: "25px", color: "#0093FB" }}>
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                        <span className="label">ORDER DETAILS: </span>
                        <span className="value" style={{ marginRight: "20px" }}>
                          {popupData.order_id}
                        </span>
                      </p>
                    </Box>
                    <table className="table">
                      <thead>
                        <tr>
                        <th> Table no.</th>
                          <th>Item Id</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(popupData.cart).map(([itemId, cartItem], index) => (
                          <tr key={index}>
                            <td>{popupData.table_id}</td>
                            <td>{itemId}</td>
                            <td>{cartItem[0]}</td>
                            <td>{cartItem[1]}</td>
                            <td>{cartItem[2]}</td>
                            <td>{cartItem[1] * cartItem[2]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>TOTAL</p>
                      <p style={{ fontSize: "25px", fontFamily: "Poppins", fontWeight: 600 }}>
                        RS {popupData.grand_total.toLocaleString()}
                      </p>
                    </div>
                  </div>
                )}
              </DialogContent>
            )}
            <DialogActions className="dialog-actions">
          
            {popupData && 
  popupData.status !== 'order completed' && 
  popupData.status !== 'payment completed' && (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      style={{
        borderRadius: "12px", // Adjust the border radius as needed
        fontFamily: "Poppins", // Set the font family
        fontSize: "18px", // Adjust the font size as needed
        backgroundColor: "#0093fb", // Set the background color
      }}
      onClick={handleOrderCompleted} // Updated to call handleCompleteKOT
    >
      Order Complete
    </Button>
)}

  


            </DialogActions>
            <DialogActions className="dialog-actions">
          
            <Button
  variant="outlined"
  color="primary"
  fullWidth
  disabled={popupData?.status === 'processing'}
  style={{
    borderRadius: "12px", // Adjust the border radius as needed
    fontFamily: "Poppins", // Set the font family
    fontSize: "18px", // Adjust the font size as needed
    backgroundColor: popupData?.status === 'processing' ? "#d3d3d3" : "#FBA85D", // Set the background color based on the status
    color: popupData?.status === 'processing' ? "#a9a9a9" : "#000000", // Set the text color based on the status
    cursor: popupData?.status === 'processing' ? 'not-allowed' : 'pointer' // Change cursor based on the status

  }}
  onClick={()=>handleOrderPrintBill()} // Updated to call handleCompleteKOT
>
  Print Bill
</Button>



                <Button
  variant="outlined"
  color="primary"
  fullWidth
  disabled={popupData?.status !== 'order completed'}
  style={{
    borderRadius: "12px", // Adjust the border radius as needed
    fontFamily: "Poppins", // Set the font family
    fontSize: "18px", // Adjust the font size as needed
    backgroundColor: popupData?.status === 'order completed' ? "#058E6E" : "#d3d3d3", // Set the background color based on the status
    color: popupData?.status === 'order completed' ? "#ffffff" : "#a9a9a9" // Set the text color based on the status
  }}
  onClick={handleOrderPaymentCompleted} // Updated to call handleCompleteKOT
>
  Payment Completed
</Button>


              </DialogActions>
            <DialogActions className="dialog-actions">
            <Button
  variant="outlined"
  color="primary"
  fullWidth
  disabled={popupData?.status === 'processing'}
  style={{
    borderRadius: "12px", // Adjust the border radius as needed
    fontFamily: "Poppins", // Set the font family
    fontSize: "18px", // Adjust the font size as needed
    backgroundColor: popupData?.status === 'processing' ? "#d3d3d3" : "#F5F5F5", // Set the background color based on the status
    color: popupData?.status === 'processing' ? "#a9a9a9" : "#000000" // Set the text color based on the status
  }}
  onClick={handleEditBill} // Updated to call handleCompleteKOT
>
  Edit Bill
</Button>

    
<Button
  variant="outlined"
  color="primary"
  fullWidth
  disabled={popupData?.status === 'processing'}
  style={{
    borderRadius: "12px", // Adjust the border radius as needed
    fontFamily: "Poppins", // Set the font family
    fontSize: "18px", // Adjust the font size as needed
    backgroundColor: popupData?.status === 'processing' ? "#d3d3d3" : "#F5F5F5", // Set the background color based on the status
    color: popupData?.status === 'processing' ? "#a9a9a9" : "#000000" // Set the text color based on the status
  }}
  //onClick={handleCompleteKOT} // Updated to call handleCompleteKOT
>
  Send Bill
</Button>
      </DialogActions>
          </Dialog>
          {/* <Dialog
                  open={openPopupBill}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "50rem", // Adjust the width as needed to make it square
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <div  style={{ position: "absolute", top: 0, left: 0 }}>
                          
                      </div>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content-1" >
                    {pdfUrl && (<>
                      <div class="bill-container" ref={componentRef}>
                              <h2>{pdfUrl.name}</h2>
                              <p>{pdfUrl.address}</p>
                              {/* <p><b>FSSAI:</b> {pdfUrl.fssai}</p>
                              <p><b>GSTIN:</b> {pdfUrl.gstin}</p> 
                              <hr />
                              <p><b>Date:</b> {convertDateFormat(pdfUrl.date)} <b>Time:</b> {formatTime(pdfUrl.time)}</p>
                              <hr />
                              <p>Order No.: {pdfUrl.order_no}</p>
                              <p>Bill No.:  {pdfUrl.bill_no}</p>
                              <p>Table No:  {pdfUrl.table_no}</p>
                              <p>Payment Method: {pdfUrl.payment_method}</p>
                              <hr />
                              <table>
                                  <thead>
                                      <tr>
                                          <th>Menu-Item</th>
                                          <th>Qty</th>
                                          <th>Rate</th>
                                          <th>Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {renderItems()}
                                  </tbody>
                              </table>
                              <hr />
                              <p>Sub Total: ₹{pdfUrl?.total?.toFixed(2)}</p>
                              {/* <p>SGST @ 2.50%: ₹{pdfUrl?.sgst?.toFixed(2)}</p>
                              <p>CGST @ 2.50%: ₹{pdfUrl?.cgst?.toFixed(2)}</p>
                              <p>Discount: ₹{pdfUrl?.discount?.toFixed(2)||"0.00"}</p>
                              <p>Round Off: ₹{pdfUrl.round_off?.toFixed(2)}</p>
                              <p>Total with Tax:  ₹{(pdfUrl?.total_with_tax?.toFixed(2)-(pdfUrl?.discount?.toFixed(2)?pdfUrl?.discount?.toFixed(2):0))}</p>
                              <hr />
                               <center>
                                  <img
                                    src={`data:image/png;base64,${pdfUrl?.qr_image}`}
                                    alt="QR Code"
                                    style={{
                                      width: "50%",
                                    }}
                                  />
                                </center>
                              <p class="thank-you">----Thank You Visit Again----</p>
                          </div>
                          </>
                        )}
                      
                    </DialogContent>
                </Dialog> */}
                <Dialog
                  open={openPopupEditBill}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "45%", // Adjust the width as needed to make it square
                      height: "auto", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div> 
                    <Grid container spacing={1} sx={{paddingTop: '5%'}}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Grid container spacing={1} sx={{marginTop:'1.5%'}}>
                                <Grid item xs={3} sx={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <Box
                                    sx={{
                                          cursor: 'pointer',
                                          display: 'inline-block',
                                          height: '3rem', // Ensures the Box wraps around the content
                                          '&:hover': {
                                                    // Add any additional hover styles if needed
                                          },
                                        }}
                                        onClick={() => {
                                                      returnpage();
                                        }}
                                  >
                                        <Prev width={'100%'} height={'100%'}/>
                                  </Box>
                                </Grid>
                                <Grid item xs={9}sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:'3.5%'}}>
                                  <Typography
                                    sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '2rem',
                                          fontWeight: '600',
                                          color: HeadingTextColor,
                                          textAlign:'center',
                                        }}
                                          component="div"
                                  >
                                    Edit Bill
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <Box sx={{ width: '50%',textAlign:'center',backgroundColor:HeadingTextColor,marginTop:'1.5%',borderRadius:2}}>
                                  <Typography
                                    sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '1.5rem',
                                          fontWeight: '800',
                                          color: '#ffff',
                                        }}
                                          component="div"
                                  >
                                        Dine In
                                  </Typography>
                                </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                    </Grid>                
                  </DialogTitle>
                  {Editbill &&
                    <DialogContent className="dialog-content" >
                    <Grid container spacing={1} sx={{marginLeft:'2.5%'}}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={5} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Restuarant Name :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={Editbill?.name}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"95%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={5} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Restuarant Address :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={Editbill?.address}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"95%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={1.5} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Bill No :
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={3.45} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={Editbill?.bill_no}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"90%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={2} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Order No :
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={4} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={Editbill?.order_no}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"95%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={2.5} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Date Time : 
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={5} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={convertDateFormat(Editbill?.date)+" "+formatTime(Editbill?.time)}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"90%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={2} sx={{marginTop:'3.5%',display:'flex',justifyContent:'flex-start'}}>
                              <Typography
                                  sx={{
                                        fontFamily: 'Poppins',
                                        fontSize:'1rem',
                                        fontWeight: '500',
                                        color: '#2E2E2E',
                                      }}
                                      component="div"
                                  >
                                      Table No :
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={1.25} sx={{marginTop:'3.5%'}}>
                              <TextField
                                value={Editbill?.table_no}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"100%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop:'2.5%'}}>
                        <table style={{width:'90%',borderCollapse: 'collapse'}}>
                          <thead>
                            <tr className="EditBillHeading">
                              <th>Product Name</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>Amount</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Editbill?.items.map((item,index)=>(
                            <tr key={index}>
                              <td>
                                <div className="EditBillCellContent">
                              <TextField
                                value={item?.name}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"95%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                                </div>
                              </td>
                              <td style={{width:'30%'}}> 
                              <div className="EditBillCellContent1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',gap: '10px'}}>
                                <center>
                                <ButtonBase
                                  sx={{
                                        display: 'inline-flex',
                                        width: '15%',
                                        height: '25px',
                                        marginRight:'4%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '30%',
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'rgba(5, 142, 110, 0.2)',
                                        },
                                    }}
                                   onClick={() =>  updateQuantity(index,-1)}
                               >
                                   <IndeterminateCheckBoxIcon sx={{ color: '#058E6E'}} className='ab' />
                               </ButtonBase>
                              <TextField
                                value={item?.quantity}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"45%",
                                      marginTop: "1.5%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                            textAlign: 'center',
                                          },
                                    }}
                                />
                                <ButtonBase
                                  sx={{
                                        display: 'inline-flex',
                                        width: '15%',
                                        height: '25px',
                                        marginLeft:'4%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '30%',
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'rgba(5, 142, 110, 0.2)',
                                        },
                                    }}
                                   onClick={() =>  updateQuantity(index,1)}
                               >
                                   <AddBoxIcon sx={{ color: '#058E6E'}} className='ab' />
                               </ButtonBase>
                                </center>
                                </div>
                              </td>
                              <td style={{width:'15%'}}>
                              <div className="EditBillCellContent1">
                                <center>
                              <TextField
                                value={item?.amount}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"75%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                                </center>
                                </div>
                              </td>
                              <td style={{width:'15%'}}>
                              <div className="EditBillCellContent1">
                                <center>
                              <TextField
                                value={item?.total}
                                //onChange={handleNameChange}
                                //error={!!error}
                                //helperText={error}
                                disabled
                                sx={{
                                      width:"85%",
                                      textAlign: "center",
                                      justifyContent:"center",
                                      '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: '#5E5E5E', // Default border color
                                          },
                                          '&:hover fieldset': {
                                            borderColor: '#5E5E5E', // Border color on hover
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: '#5E5E5E', // Border color when focused
                                          },
                                          },
                                          '& .MuiInputBase-root': {
                                            height: 10, // Adjust the height here
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '15px 10px', // Adjust the padding inside the input
                                          },
                                        }}
                                        InputProps={{
                                          style: {
                                            height: '25px',
                                            color: "#5E5E5E",
                                          },
                                    }}
                                />
                                </center>
                                </div>
                              </td>
                              <td style={{width:'14%'}}>
                              <div className="EditBillCellContent1">
                                <center>
                                <Box
                                    sx={{
                                          cursor: 'pointer',
                                          display: 'inline-block',
                                          height: '1.5rem', // Ensures the Box wraps around the content
                                        }}
                                        onClick={() => {
                                          removeItem(index);
                                        }}
                                  >
                                      <Delete width={'100%'}/>
                                </Box>
                                  
                                </center>
                              </div>
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                        </Grid>
                        <Grid item xs={12} sx={{borderTop:'1px solid black',borderBottom:'1px solid black',marginRight:'10.5%',marginTop:'2.5%'}}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '5%'}}>
                                  <input 
                                    type="checkbox" 
                                    checked
                                    disabled
                                    /* onChange={() => handleCheckboxClick(itemId,popupData.cart)} */
                                    style={{ cursor: 'pointer',width: '20%' }} 
                                  /> 
                                 <span style={{ whiteSpace: 'nowrap' }}>SGST @2.5%</span>
                                  </Grid>
                                  <Grid item xs={6} sx={{marginTop: '5%'}}>
                                    <span style={{ whiteSpace: 'nowrap' }}><b>{Editbill?.sgst?.toFixed(2)}</b></span>
                                  </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '5%'}}>
                                  <input 
                                    type="checkbox" 
                                    checked
                                    disabled
                                    /* onChange={() => handleCheckboxClick(itemId,popupData.cart)} */
                                    style={{ cursor: 'pointer',width: '20%' }} 
                                  /> 
                                 <span style={{ whiteSpace: 'nowrap' }}>CGST @2.5%</span>
                                </Grid>
                                <Grid item xs={6} sx={{marginTop: '5%'}}>
                                  <span style={{ whiteSpace: 'nowrap' }}><b>{Editbill?.cgst?.toFixed(2)}</b></span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '2%'}}>
                                  <input 
                                    type="checkbox" 
                                    checked
                                    disabled
                                    /* onChange={() => handleCheckboxClick(itemId,popupData.cart)} */
                                    style={{ cursor: 'pointer',width: '20%' }} 
                                  /> 
                                 <span style={{ whiteSpace: 'nowrap' }}>CGST @2.5%</span>
                                </Grid>
                                <Grid item xs={6} sx={{marginTop: '2%'}}>
                                  <span style={{ whiteSpace: 'nowrap' }}><b>{Editbill?.cgst?.toFixed(2)}</b></span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '2%'}}> 
                                 <span style={{ whiteSpace: 'nowrap' }}>Total</span>
                                </Grid>
                                <Grid item xs={6} sx={{marginTop: '2%'}}>
                                  <span style={{ whiteSpace: 'nowrap' }}><b>₹{Editbill?.total_with_tax?.toFixed(2)}</b></span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '5%'}}>
                          <Button
                            variant="outlined"
                            color="primary"
                           /*  disabled={popupData?.status === 'processing'} */
                            style={{
                              borderRadius: "12px", // Adjust the border radius as needed
                              fontFamily: "Poppins", // Set the font family
                              width:'50%',
                              fontSize: "18px", // Adjust the font size as needed
                              backgroundColor: HeadingTextColor,
                              color:'#ffff',
                            }}
                            onClick={handleEditBillData}
                          >
                            Edit Bill
                          </Button>
                        </Grid>
                    </Grid>
                    </DialogContent>
                  }
                </Dialog>
        </div>
      </Grid>
    </Grid>
  );
};

export default OrderHistory;
