import { Box, Button, Divider, Grid, Paper, TextField, Typography,Checkbox, FormControlLabel, FormGroup, FormControl, MenuItem, Select } from "@mui/material";
import React, { useState,useRef, useEffect} from "react";
import Navbar from "../../../../components/Navbar";
import UserDetails from "../../../../components/UserDetails";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ReactComponent as Prev } from '../../../../assets/Prev.svg';
import { ReactComponent as Search } from '../../../../assets/searchproduct.svg';
import {ReactComponent as Upload} from '../../../../assets/Upload.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddProduct, EditProduct, get_product_data, get_product_details } from "../ManageActions/ManageActions";
import loader from '../../../../assets/EditLoader.gif';


const ProductForm = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = sessionStorage?.getItem("Token");
    const product_id = sessionStorage?.getItem("ProductId");
    const email = sessionStorage?.getItem("Email");
    const product = sessionStorage?.getItem("product");
    const role = sessionStorage?.getItem("Role");
    const loadingData = useSelector((state)=>state.manageProduct.ManageResponseDataLoading);
    const product_name= useSelector((state)=>state.manageProduct.GetProductResponseData);
    const fileInputRef = useRef(null);
    const checkboxStyles = {
        '& .MuiSvgIcon-root': {
            fontSize: 32, // Increase the size of the checkbox
            color: '#2e2e2e', // Set the color of the checkbox
        },
        '& .MuiCheckbox-root': {
            borderColor: '#2e2e2e', // Set the border color
        }
    };
    const [checked, setChecked] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
      });
    const [Analytics,setAnalytics]=useState({
        yes: true,
        no: false,
    });
    const [Inventory,setInventory]=useState({
        yes: true,
        no: false,
    });
    const [PBO,setPBO]=useState({
        yes: true,
        no: false,
    });
    const [LiveUpdate,setLiveUpdate]=useState({
        yes: true,
        no: false,
    });
    const [Bar,setBar]=useState({
        yes: true,
        no: false,
    });
    const [EmployeeManagement,setEmployeeManagement]=useState({
        yes: true,
        no: false,
    });
    const [QrModel,setQrModel]=useState({
        yes: true,
        no: false,
    });
    const [ActiveStatus,setActiveStatus]=useState({
        yes: true,
        no: false,
    });
    
    const handleChangeAnalytics = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setAnalytics((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeInventory = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setInventory((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangePBO = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setPBO((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeLiveUpdate = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setLiveUpdate((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeBar = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setBar((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeEmployeeManagement = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setEmployeeManagement((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeQrModel = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setQrModel((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };
    const handleChangeProductStatus = (event) => {
        const { name, checked } = event.target;

        // Update the state
        setActiveStatus((prevState) => {
            // If checkbox2 is checked, uncheck checkbox3
            if (name === 'yes' && checked) {
                return { yes: true, no: false };
            }
            else if(name === 'no' && checked) {
                return { yes: false, no: true };
            }

            // Otherwise, update the specific checkbox
            return { ...prevState, [name]: checked };
        });
    };

    const[DisplayData,setDisplayData]=useState("editdata")
    const [imageData, setImageData] = useState(null);
    
    const [ModelType,setModelType]=useState('dinein');
    const [ModelTypeError,setModelTypeError]=useState('');
    const [Country,setCountry]=useState('');
    const [CountryError,setCountryError]=useState('');
    const [SubType,setSubType]=useState('post_pay');
    const [SubTypeError,setSubTypeError]=useState('');
    const [State,setState]=useState('');
    const [StateError,setStateError]=useState('');
    const [Sgst_Cgst,setSgst_Cgst]=useState('2.5');
    const [Sgst_CgstError,setSgst_CgstError]=useState('');
    const [Address,setAddress]=useState('');
    const [AddressError,setAddressError]=useState('');
    const [Cu,setCu]=useState('');
    const [CuError,setCuError]=useState('');
    const [Fssai,setFssai]=useState('');
    const [FssaiError,setFssaiError]=useState('');
    const [Dd,setDd] = useState('');
    const [DdError,setDdError] = useState('');
    const [Mm,setMm]=useState('');
    const [MmError,setMmError]=useState('');
    const [Yyyy,setYyyy]=useState('');
    const [YyyyError,setYyyyError]=useState('');
    const [Gstin,setGstin]=useState('');
    const [GstinError,setGstinError]=useState('');
    const [Dd1,setDd1] = useState('');
    const [Dd1Error,setDd1Error] = useState('');
    const [Mm1,setMm1]=useState('');
    const [Mm1Error,setMm1Error]=useState('');
    const [Yyyy1,setYyyy1]=useState('');
    const [Yyyy1Error,setYyyy1Error]=useState('');
    const [Hh,setHh]=useState('');
    const [HhError,setHhError]=useState('');
    const [MM,setMM] = useState('');
    const [MMError,setMMError] = useState('');
    const [SS,setSS]=useState('');
    const [SSError,setSSError]=useState('');
    const [Hh1,setHh1]=useState('');
    const [HhError1,setHhError1]=useState('');
    const [MM1,setMM1] = useState('');
    const [MMError1,setMMError1] = useState('');
    const [SS1,setSS1]=useState('');
    const [SSError1,setSSError1]=useState('');
    const [BNPT,setBNPT]=useState('');
    const [BNPTError,setBNPTError]=useState('');
    const [BNP,setBNP]=useState('');
    const [BNPError,setBNPError]=useState('');
    const [BN,setBN]=useState('');
    const [BNError,setBNError]=useState('');
    const [ON,setON]=useState('');
    const [ONError,setONError]=useState('');
    const [ML,setML]=useState('');
    const [MLError,setMLError] = useState('');
    const [UL,setUL]=useState('');
    const [ULError, setULError]=useState('');
    const [CL,setCL]=useState('');
    const [CLError,setCLError]=useState('');
    const [BL,setBL]=useState('');
    const [BLError,setBLError]=useState('');

    const gstinRegex = /^([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z]{1})([A-Z0-9]{1})(Z)([A-Z0-9]{1})$/;
    const [SelectProduct,setSelectProduct]=useState('Select Product');
    const [SelectProductKey,setSelectProductKey]=useState('');

    useEffect(()=>{
        if(product_name.length === 0)
        {
            dispatch(get_product_data(email,token))
        }
    },[dispatch])
    useEffect(()=>{
        if(product === 'Add')
        {
            setDisplayData('adddata')
        }
        else if(product === 'Manage')
        {
            setDisplayData('managedata')
        }
    },[product])
    const handlechangeSelectProduct=(event)=>{
        const selectedOrganization = event.target.value;
        const selectedProductIndex = product_name.organization_names.indexOf(selectedOrganization);
        const selectedProductId = product_name.product_ids[selectedProductIndex];
    
        setSelectProduct(selectedOrganization);
        setSelectProductKey(selectedProductId);
    }
    const handlechangeML=(event)=>{
        setMLError('');
        setML(event.target.value);
    }
    const handlechangeUL=(event)=>{
        setULError('');
        setUL(event.target.value);
    }
    const handlechangeCL=(event)=>{
        setCLError('');
        setCL(event.target.value);
    }
    const handlechangeBL=(event)=>{
        setBLError('');
        setBL(event.target.value);
    }
    const handlechangeBNPT=(event)=>{
        setBNPTError('');
        setBNPT(event.target.value)
    }
    const handlechangeBNP=(event)=>{
        setBNPError('');
        setBNP(event.target.value)
    }
    const handlechangeBN=(event)=>{
        setBNError('');
        setBN(event.target.value)
    }
    const handlechangeON=(event)=>{
        setONError('');
        setON(event.target.value)
    }
    const handlechangeHh = (event) =>{
        if(parseInt(event.target.value)>23 && parseInt(event.target.value)<0)
        {
            setHhError("Hour can't be more than 23 or less than 0")
        }
        else
        {
            setHh(event.target.value)
            setHhError('')
        }
    }
    const handlechangeMM = (event) =>{
        if(parseInt(event.target.value)>59 && parseInt(event.target.value)<0)
        {
            setMMError("Minutes can't be more than 60 or less than 0")
        }
        else
        {
            setMM(event.target.value)
            setMMError('')
        }
    }
    const handlechangeSS = (event)=>{
       
        setSS(event.target.value);
        setSSError('');       
    }
    const handlechangeHh1 = (event) =>{
        if(parseInt(event.target.value)>23 && parseInt(event.target.value)<0)
        {
            setHhError1("Hour can't be more than 23 or less than 0")
        }
        else
        {
            setHh1(event.target.value)
            setHhError1('')
        }
    }
    const handlechangeMM1 = (event) =>{
        if(parseInt(event.target.value)>59 && parseInt(event.target.value)<0)
        {
            setMMError1("Minutes can't be more than 60 or less than 0")
        }
        else
        {
            setMM1(event.target.value)
            setMMError1('')
        }
    }
    const handlechangeSS1 = (event)=>{
            setSS1(event.target.value)
            setSSError1('')
    }

    const handleChangeModelType = (event)=>{
        setModelType(event.target.value);
        setModelTypeError('');
    }
    const handleChangeCountry = (event)=>{
        setCountry(event.target.value);
        setCountryError('');
    }
    const handleChangeSubType = (event)=>{
        setSubType(event.target.value)
        setSubTypeError('')
    }
    const handlechangeGstin =(event)=>{
        setGstin(event.target.value);
        setGstinError('')
    }
    const handlechangeState = (event)=>{
        setState(event.target.value)
        setStateError('')
    }
    const handlechangeSgst_Cgst = (event)=>{
        /* if(event.target.value<2.5)
        {
            setSgst_CgstError('Value not less than 2.5')
            return
        } */
        setSgst_Cgst(event.target.value)
        setSgst_CgstError('')
    }
    const handlechangeAddress = (event)=>{
        setAddress(event.target.value)
        setAddressError('')
    }
    const handlechangeCu = (event) =>{
        setCu(event.target.value)
        setCuError('')
    }
    const handlechangeFssai = (event)=>{
        setFssai(event.target.value)
        setFssaiError('')
    }
    const handlechangeDd = (event)=>{
        setDd(event.target.value)
        if(parseInt(event.target.value) > 31)
        {
            setDdError("Date can't be more than 31")
        }
        else if(parseInt(event.target.value) < 1)
        {
            setDdError("Date can't be less than 1")
        }
        else
        {
            setDdError('')
        } 
    }
    const handlechangeMm = (event) =>{
        setMm(event.target.value)
        if(parseInt(event.target.value) === 1 || parseInt(event.target.value) === 3 || parseInt(event.target.value) === 5 || parseInt(event.target.value) === 7 || parseInt(event.target.value) === 8 || parseInt(event.target.value) === 10 || parseInt(event.target.value) === 12)
            {
                if(parseInt(Dd) > 31)
                {
                    setDdError("Date can't be more than 31")
                    return
                }
            }
            else if(parseInt(event.target.value) === 4 || parseInt(event.target.value) === 6 || parseInt(event.target.value) === 9 || parseInt(event.target.value) === 11)
            {
                if(parseInt(Dd) > 30)
                {
                    setDdError("Date can't be more than 30")
                    return
                }
            }
            else if(parseInt(event.target.value) === 2)
            {
                if(parseInt(Dd) > 28)
                {
                    setDdError("Date can't be more than 28")
                    return
                }
            }
            else if(parseInt(event.target.value) > 12)
            {
                setMmError("Month can't be more than 12")
                return
            }
            else if(parseInt(event.target.value)<1)
            {
                setMmError("Month can't be less than 1")
                return
            }
            else
            {
                setMmError('')
                return
            }
    }
    const handlechangeYyyy = (event) =>{
        setYyyy(event.target.value)
        setYyyyError('');
        if(parseInt(event.target.value)%4 === 0)
        {
            if(parseInt(Mm) === 2)
            {
                if(parseInt(Dd) > 29)
                {
                    setDdError("Date can't be more than 28")
                }
            }
        }
    }
    const handlechangeDd1 = (event)=>{
        setDd1(event.target.value)
        if(parseInt(event.target.value) > 31)
        {
            setDd1Error("Date can't be more than 31")
        }
        else if(parseInt(event.target.value) < 1)
        {
            setDd1Error("Date can't be less than 1")
        }
        else
        {
            setDd1Error('')
        } 
    }
    const handlechangeMm1 = (event) =>{
        setMm1(event.target.value)
        if(parseInt(event.target.value) === 1 || parseInt(event.target.value) === 3 || parseInt(event.target.value) === 5 || parseInt(event.target.value) === 7 || parseInt(event.target.value) === 8 || parseInt(event.target.value) === 10 || parseInt(event.target.value) === 12)
            {
                if(parseInt(Dd1) > 31)
                {
                    setDd1Error("Date can't be more than 31")
                }
            }
            else if(parseInt(event.target.value) === 4 || parseInt(event.target.value) === 6 || parseInt(event.target.value) === 9 || parseInt(event.target.value) === 11)
            {
                if(parseInt(Dd1) > 30)
                {
                    setDd1Error("Date can't be more than 30")
                }
            }
            else if(parseInt(event.target.value) === 2)
            {
                if(parseInt(Dd1) > 28)
                {
                    setDd1Error("Date can't be more than 28")
                }
            }
            else if(parseInt(event.target.value) > 12)
            {
                setMm1Error("Month can't be more than 12")
            }
            else if(parseInt(event.target.value)<1)
            {
                setMm1Error("Month can't be less than 1")
            }
            else
            {
                setMm1Error('')
            }
    }
    const handlechangeYyyy1 = (event) =>{
        setYyyy1(event.target.value)
        setYyyy1Error('')
        if(parseInt(event.target.value)%4 === 0)
        {
            if(parseInt(Mm1) === 2)
            {
                if(parseInt(Dd1) > 29)
                {
                    setDd1Error("Date can't be more than 28")
                }
            }
        }
    }

    const handleChange = (event) => {
        setChecked({
          ...checked,
          [event.target.name]: event.target.checked,
        });
      };

    const handleImageUpload = () =>{
        fileInputRef.current.click();
    }
    
    const returnpage = () =>{
        if(product==='Edit')
        {
            if(DisplayData==='adddata')
            {
                setDisplayData('editdata')
            }
            else if(DisplayData==='editdata')
            {
                navigate("/manage")
            }
            else if(DisplayData==='nextdata')
            {
                setDisplayData('adddata')
            }
            else if(DisplayData==='feature')
            {
                setDisplayData('nextdata')
            }
        }
        else if(DisplayData==='adddata'|| DisplayData==='editdata')
        {
            navigate("/manage")
        }
        else if(DisplayData==='nextdata')
        {
            setDisplayData('adddata')
        }
        else if(DisplayData==='feature')
        {
            setDisplayData('nextdata')
        }
    }
    const next=()=>
    {
        if(ModelTypeError === '' && CountryError === '' && SubTypeError === '' && StateError === '' && Sgst_CgstError === '' && AddressError === '' && CuError === '' && FssaiError === '' && DdError === '' && MmError === '' && YyyyError === '' && Dd1Error === '' && Mm1Error === '' && Yyyy1Error === '' && GstinError === '')
        {
            if(ModelType !== '' && Country !== '' && SubType !== '' && State !== '' && Sgst_Cgst !== '' && Address !== '' && Cu !== '' && Fssai !== '' && Dd !== '' && Mm !== '' && Yyyy !== '' && Dd1 !== '' && Mm1 !== '' && Yyyy1 !== '' && Gstin !== '' && imageData !== '' && gstinRegex.test(Gstin))
            {
                if(parseInt(Yyyy) < parseInt(Yyyy1))
                {
                    setDisplayData('nextdata');
                }
                else if(parseInt(Yyyy) === parseInt(Yyyy1))
                {
                    if(parseInt(Mm)<parseInt(Mm1))
                    {
                        setDisplayData('nextdata');
                    }
                    else if(Mm === Mm1)
                    {
                        if(parseInt(Dd)<parseInt(Dd1))
                        {
                            setDisplayData('nextdata'); 
                        }
                        else
                        {
                            setDdError('Error');
                        }
                    }
                    else    
                    {
                        setMmError('Error');
                    }
                }
                else{
                    setYyyyError('Error');
                }
            }
            else
            {
                if(ModelType === '')
                {
                    setModelTypeError("Can't be empty")
                }
                if(Country === '')
                {
                    setCountryError("Can't be empty")
                }
                if(SubType === '')
                {
                    setSubTypeError("Can't be empty")
                }
                if(State === '')
                {
                    setStateError("Can't be empty")
                }
                if(Sgst_Cgst === '')
                {
                    setSgst_CgstError("Can't be empty")
                }
                if(Address === '')
                {
                    setAddressError("Can't be empty")
                }
                if(Cu === '')
                {
                    setCuError("Can't be empty")
                }
                if(Fssai === '')
                {
                    setFssaiError("Can't be empty")
                }
                if(Dd === '')
                {
                    setDdError("Can't be empty")
                }
                if(Mm === '')
                {
                    setMmError("Can't be empty")
                }
                if(Yyyy === '')
                {
                    setYyyyError("Can't be empty")
                }
                if(Dd1 === '')
                {
                    setDd1Error("Can't be empty")
                }
                if(Mm1 === '')
                {
                    setMm1Error("Can't be empty")
                }
                if(Yyyy1 === '')
                {
                    setYyyy1Error("Can't be empty")
                }
                if(Gstin === '')
                {
                    setGstinError("Can't be empty")
                }
                if(!gstinRegex.test(Gstin))
                {
                    setGstinError("Wrong Gst number")
                }
            }
        }  
    }
    const save_next_data = ()=>{
        if(HhError === '' && HhError1 === '' && MMError === '' && Mm1Error === '' && SSError === '' && SSError1 === '' && BNPTError === '' && BNPError === '' && BNError === '' && ONError === '')
        {
            if(Hh !== '' && Hh1 !== '' && MM !== '' && MM1 !== '' && SS !== '' && SS1 !== '' && BNPT !== '' && BNP !== '' && BN !== '' && ON !== '')
            {
                setDisplayData('feature')
            }
            else
            {
                if(Hh === '')
                {
                    setHhError("Can't be empty")
                }
                if(Hh1 === '')
                {
                    setHhError1("Can't be empty")
                }
                if(MM === '')
                {
                    setMMError("Can't be empty")
                }
                if(MM1 === '')
                {
                    setMMError1("Can't be empty")
                }
                if(SS === '')
                {
                    setSSError("Can't be empty")
                }
                if(SS1 === '')
                {
                    setSSError1("Can't be empty")
                }
                if(BNPT === '')
                {
                    setBNPTError("Can't be empty")
                }
                if(BNP === '')
                {
                    setBNPError("Can't be empty")
                }
                if(BN === '')
                {
                    setBNError("Can't be empty")
                }
                if(ON === '')
                {
                    setONError("Can't be empty")
                }
                if(ML === ''){
                    setMLError("Can't be empty")
                }
                if(UL === ''){
                    setULError("Can't be empty")
                }
                if(CL === ''){
                    setCLError("Can't be empty")
                }
                if(BL === ''){
                    setBLError("Can't be empty")
                }
            }
        }    
    }
    const save_Product= async()=>{
        const sub_start=Yyyy+'-'+Mm+'-'+Dd;
        const sub_end=Yyyy1+'-'+Mm1+'-'+Dd1;
        const Rot=Hh+':'+MM+':'+SS;
        const Rct=Hh1+':'+MM1+':'+SS1;
        const sgst=Sgst_Cgst;
        const Cgst=Sgst_Cgst;
        var Rond=0;
        var Ronm=0;
        var Rbny=0;
        var Analyt=0;
        var live=0;
        var inven=0;
        var post_pay=0;
        var pre_pay=0;
        var bar=0;
        var employeeManagement=0;
        var qrModel=0;
        var AStatus=0;
        if(checked.checkbox1)
        {
            Rond=1
        }
        if(checked.checkbox2)
        {
            Ronm=1
        }
        if(checked.checkbox3)
        {
            Rbny=1
        }
        if(Analytics.yes)
        {
            Analyt=1
        }
        if(Inventory.yes)
        {
            inven=1
        }
        if(LiveUpdate.yes)
        {
            live=1
        }
        if(Bar.yes){
            bar=1
        }
        if(EmployeeManagement.yes){
            employeeManagement=1
        }
        if(QrModel.yes){
            qrModel=1
        }
        if(ActiveStatus.yes){
            AStatus=1
        }
        if(SubType==='post_pay')
        {
            post_pay=1
        }
        else
        {
            pre_pay=1
        }
        if(product==='Add')
        {
            await dispatch(AddProduct(email,product_id,token,Cu,ModelType,sub_start,sub_end,Address,Fssai,Gstin,sgst,Cgst,post_pay,pre_pay,live,inven,Analyt,imageData,BNPT,BNP,Rot,Rct,Rond,Ronm,Rbny,BN,ON,Country,State, parseInt(ML), parseInt(UL), parseInt(CL), parseInt(BL), bar, employeeManagement, qrModel, AStatus));
        }
        else
        {
            await dispatch(EditProduct(email,SelectProductKey,token,Cu,ModelType,sub_start,sub_end,Address,Fssai,Gstin,sgst,Cgst,post_pay,pre_pay,live,inven,Analyt,imageData,BNPT,BNP,Rot,Rct,Rond,Ronm,Rbny,BN,ON,Country,State, parseInt(ML), parseInt(UL), parseInt(CL), parseInt(BL), bar, employeeManagement, qrModel, AStatus));
        }
        navigate("/manage")
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check if the file is an image
            if (!file.type.startsWith('image/')) {
            console.error('File is not an image.');
            return;
            }

            const reader = new FileReader();
            reader.onload = () => {
            const base64String = reader.result;
            setImageData(base64String);
            };
            reader.readAsDataURL(file);
        }
      };
      const search=async()=>{
        if(SelectProduct!=='Select Product' && DisplayData ==='editdata')
        {    
            const product_details = await dispatch(get_product_details(email,SelectProductKey,token));
            const [year, month, day] = product_details?.['subscription_start'].split('T')[0].split('-');
            const [year1, month1, day1] = product_details?.['subscription_end'].split('T')[0].split('-');
            const [h, m,s] = product_details?.['restaurant_open_time'].trim().split(':');
            const [h1, m1,s1] = product_details?.['restaurant_closing_time'].trim().split(':');
            setYyyy(year);
            setMm(month);
            setDd(day);
            setYyyy1(year1);
            setMm1(month1);
            setDd1(day1);
            setHh(h);
            setMM(m);
            setSS(s);
            setHh1(h1);
            setMM1(m1);
            setSS1(s1);
            setCu(product_details['organization_name']);
            setModelType(product_details['model_type']);
            setFssai(product_details['fssai']);
            setGstin(product_details['gstin']);
            setSgst_Cgst(product_details['sgst']);
            if(product_details['post_pay'] === 1)
            {
                setSubType('post_pay')
            }
            else
            {
                setSubType('pre_pay')
            }
            setAnalytics((prevState) => { 
                if (product_details['analytics']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setInventory((prevState) => { 
                if (product_details['inventory']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setLiveUpdate((prevState) => { 
                if (product_details['live_updates']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setBar((prevState) => { 
                if (product_details['bar']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setEmployeeManagement((prevState) => { 
                if (product_details['employee_management']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setQrModel((prevState) => { 
                if (product_details['qr_model']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setActiveStatus((prevState) => { 
                if (product_details['subscription_status']) {
                    return { yes: true, no: false };
                }
                else {
                    return { yes: false, no: true };
                }
            });
            setImageData(product_details['qr_url']);
            setBNPT(product_details['bill_no_prefix_type']);
            setBNP(product_details['bill_no_prefix']);
            setBN(product_details['bill_no']);
            setON(product_details['current_order_no']);
            setML(product_details['manager_limit']);
            setCL(product_details['user_limit']);
            setUL(product_details['chef_limit']);
            setBL(product_details['bartender_limit']);
            setChecked({
                'checkbox1': product_details['reset_order_no_daily'] === 1?true:false,
                'checkbox2': product_details['reset_order_no_monthly'] === 1?true:false,
                'checkbox3': product_details['reset_bill_no'] === 1?true:false,
            });
            
            setAddress(product_details['address'])
            setDisplayData('adddata');
        }
        else if(SelectProduct!=='Select Product'){
            await window.location.reload();
            await sessionStorage.setItem('ProductId',SelectProductKey);
            await sessionStorage.setItem('Role','manager');
            navigate('/reserve')
        }
      }

return(
    <>
        <Grid container spacing={1} sx={{filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto",height:"100vh"}}>
            {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="manage" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"99vh"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={11.8} sx={{marginTop:"1.5%"}}>
                    <Paper elevation={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{margin: 1}}>
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            height: '3rem', // Ensures the Box wraps around the content
                                            '&:hover': {
                                                // Add any additional hover styles if needed
                                            },
                                        }}
                                        onClick={() => {
                                                returnpage();
                                            }}
                                    >
                                        <Prev width={'100%'} height={'100%'}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:"0.5%"}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontWeight: '800',
                                            color: '#2E2E2E',
                                            paddingLeft:"1.5%",
                                        }}
                                            variant="h3"
                                            noWrap
                                            component="div"
                                    >
                                           {product} Product
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display: DisplayData === 'editdata' || DisplayData === 'managedata'? 'show':'none',marginLeft:"1.5%",marginTop:'10%',marginBottom:'20%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{marginBottom:'0.5%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Poppins',
                                                        fontWeight: '500',
                                                        color: '#2E2E2E',
                                                        paddingLeft:"1.5%",
                                                    }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                        Search Product
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{margin: 1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <FormControl sx={{ fontSize: '1rem',width:'15%'}}>
                                                            <Select
                                                            labelId="demo-customized-select-label"
                                                            id="demo-customized-select"
                                                            value={SelectProduct}
                                                            onChange={handlechangeSelectProduct}
                                                            sx={{
                                                                height: 'auto',
                                                                color: '#5E5E5E',
                                                                fontSize: '1rem',
                                                                fontFamily: 'Poppins',
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                borderColor: '#5E5E5E',
                                                            }}
                                                            >
                                                                <MenuItem value="Select Product">
                                                                    <em>Select Product</em>
                                                                </MenuItem>
                                                                {product_name && product_name?.organization_names?.map((organization, index) => {
                                                                    return (organization !== 'ADMIN' ?(
                                                                    <MenuItem key={product_name?.product_ids[index]} value={organization} sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    {organization}
                                                                    </MenuItem>
                                                                ):null)})}
                                                            </Select>
                                                    </FormControl>
                                                    <Box
                                                        sx={{
                                                            cursor: 'pointer',
                                                            display: 'inline-block',
                                                            paddingLeft:'1.5%',
                                                            height: '3rem', // Ensures the Box wraps around the content
                                                            '&:hover': {
                                                                // Add any additional hover styles if needed
                                                            },
                                                        }}
                                                        onClick={() => {
                                                                search();
                                                            }}
                                                    >
                                                        <Search width={'100%'} height={'100%'}/>
                                                    </Box>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{display: DisplayData === 'adddata'? 'show':'none',marginLeft:"1.5%"}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            Organisation :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Cu}
                                                            onChange={handlechangeCu}
                                                            placeholder="CU"
                                                            error={!!CuError}
                                                            helperText={CuError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000" }} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Fssai}
                                                            onChange={handlechangeFssai}
                                                            placeholder="FSSAI"
                                                            error={!!FssaiError}
                                                            helperText={FssaiError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            Model Type
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControl sx={{ fontSize: '1rem',width:'90%' }}>
                                                            <Select
                                                            labelId="demo-customized-select-label"
                                                            id="demo-customized-select"
                                                            value={ModelType}
                                                            onChange={handleChangeModelType}
                                                            sx={{
                                                                height: 'auto',
                                                                color: '#5E5E5E',
                                                                fontSize: '1rem',
                                                                fontFamily: 'Poppins',
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                borderColor: '#5E5E5E',
                                                            }}
                                                            >
                                                                <MenuItem value="dinein" sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    Dine In
                                                                </MenuItem>
                                                                <MenuItem value="payfirst" sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    Pay First
                                                                </MenuItem>
                                                                <MenuItem value="simpleorder" sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    Simple Order
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        

                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000" }} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Country}
                                                            onChange={handleChangeCountry}
                                                            placeholder="Country"
                                                            error={!!CountryError}
                                                            helperText={CountryError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            Subscription Type :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                    <FormControl sx={{ fontSize: '1rem',width:'90%' }}>
                                                            <Select
                                                            labelId="demo-customized-select-label"
                                                            id="demo-customized-select"
                                                            value={SubType}
                                                            onChange={handleChangeSubType}
                                                            sx={{
                                                                height: 'auto',
                                                                color: '#5E5E5E',
                                                                fontSize: '1rem',
                                                                fontFamily: 'Poppins',
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                borderColor: '#5E5E5E',
                                                            }}
                                                            >
                                                                <MenuItem value="post_pay" sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    Post Pay
                                                                </MenuItem>
                                                                <MenuItem value="pre_pay" sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    Pre Pay
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000" }} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={State}
                                                            onChange={handlechangeState}
                                                            placeholder="State"
                                                            error={!!StateError}
                                                            helperText={StateError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            SGST/CGST  :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Sgst_Cgst}
                                                            onChange={handlechangeSgst_Cgst}
                                                            placeholder="Sgst_Cgst"
                                                            error={!!Sgst_CgstError}
                                                            helperText={Sgst_CgstError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000" }} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Address}
                                                            onChange={handlechangeAddress}
                                                            placeholder="Address"
                                                            error={!!AddressError}
                                                            helperText={AddressError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            Subscription Start :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={Dd}
                                                                    onChange={handlechangeDd}
                                                                    placeholder="DD"
                                                                    type="number"
                                                                    error={!!DdError}
                                                                    helperText={DdError}
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={Mm}
                                                                    onChange={handlechangeMm}
                                                                    placeholder="MM"
                                                                    type="number"
                                                                    error={!!MmError}
                                                                    helperText={MmError}
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5.3}>
                                                            <TextField
                                                                    value={Yyyy}
                                                                    onChange={handlechangeYyyy}
                                                                    placeholder="YYYY"
                                                                    error={!!YyyyError}
                                                                    helperText={YyyyError}
                                                                    type="number"
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000" }} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            value={Gstin}
                                                            onChange={handlechangeGstin}
                                                            placeholder="GSTIN"
                                                            error={!!GstinError}
                                                            helperText={GstinError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2.5}>
                                                        <Typography
                                                            sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    color: '#2E2E2E',
                                                                    marginLeft: "4.5%",
                                                                    paddingTop: "1.5%"
                                                                }}
                                                                variant="h6"
                                                                noWrap
                                                                component="div"
                                                        >
                                                            Subscription End :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                    <Grid container spacing={1}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={Dd1}
                                                                    onChange={handlechangeDd1}
                                                                    placeholder="DD"
                                                                    type="number"
                                                                    error={!!Dd1Error}
                                                                    helperText={Dd1Error}
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    value={Mm1}
                                                                    onChange={handlechangeMm1}
                                                                    placeholder="MM"
                                                                    type="number"
                                                                    error={!!Mm1Error}
                                                                    helperText={Mm1Error}
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5.3}>
                                                            <TextField
                                                                    value={Yyyy1}
                                                                    onChange={handlechangeYyyy1}
                                                                    placeholder="YYYY"
                                                                    error={!!Yyyy1Error}
                                                                    helperText={Yyyy1Error}
                                                                    type="number"
                                                                    sx={{
                                                                        width: "90%",
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#5E5E5E', // Default border color
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color on hover
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#5E5E5E', // Border color when focused
                                                                            },
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            height: '50px', // Adjust the height here
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            padding: '0', // Remove padding inside the input
                                                                            textAlign: 'center', // Center the text (including placeholder)
                                                                            height: '100%', // Ensure the input takes the full height of its container
                                                                        },
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            height: '50px',
                                                                            color: "#5E5E5E",
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1.5} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                        <Divider orientation="vertical" sx={{ borderColor: "#000000"}} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <input
                                                            type="file"
                                                            ref={fileInputRef}
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileChange}
                                                        />
                                                        <Button
                                                            onClick={() => handleImageUpload()}
                                                            variant="outlined"
                                                            startIcon={<Upload />}
                                                            sx={{
                                                                width:'90%',
                                                                height: '100%',
                                                                color: imageData===null?'#fff':'#00000',
                                                                backgroundColor: imageData===null?'#0093FB':'#F2F2F2',
                                                                border: '1px solid #0093FB',
                                                                '&:hover': {
                                                                    backgroundColor: '#0093FB',
                                                                    border: '1px solid #0093FB',
                                                                    color:"#ffff",
                                                                },
                                                            }}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{display:"flex",justifyContent:"flex-end",marginRight:"3.5%",marginBottom:"2.5%",marginTop:"1.5%"}}>
                                        <Button
                                            variant="contained"
                                            style={{
                                            borderRadius: "10px", // Adjust the border radius as needed
                                            fontFamily: "Poppins", // Set the font family
                                            fontSize: "18px", // Adjust the font size as needed
                                            backgroundColor: "#058E6E",
                                            textAlign: "center" ,
                                            width:"20%"// Set the background color
                                            }}
                                            onClick={()=>next()}
                                        >
                                            Save and Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display: DisplayData === 'nextdata'? 'show':'none'}}>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Restuarant Open Time  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            value={Hh}
                                                            onChange={handlechangeHh}
                                                            placeholder="HH"
                                                            type="number"
                                                            error={!!HhError}
                                                            helperText={HhError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            value={MM}
                                                            onChange={handlechangeMM}
                                                            placeholder="MM"
                                                            type="number"
                                                            error={!!MMError}
                                                            helperText={MMError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5.3}>
                                                    <TextField
                                                            value={SS}
                                                            onChange={handlechangeSS}
                                                            placeholder="SS"
                                                            error={!!SSError}
                                                            helperText={SSError}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Manager Limit  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <TextField
                                                    value={ML}
                                                    onChange={handlechangeML}
                                                    placeholder="1 or 2 etc."
                                                    error={!!MLError}
                                                    helperText={MLError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Restuarant Closing Time  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            value={Hh1}
                                                            onChange={handlechangeHh1}
                                                            placeholder="HH"
                                                            type="number"
                                                            error={!!HhError1}
                                                            helperText={HhError1}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            value={MM1}
                                                            onChange={handlechangeMM1}
                                                            placeholder="MM"
                                                            type="number"
                                                            error={!!MMError1}
                                                            helperText={MMError1}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5.3}>
                                                    <TextField
                                                            value={SS1}
                                                            onChange={handlechangeSS1}
                                                            placeholder="SS"
                                                            error={!!SSError1}
                                                            helperText={SSError1}
                                                            sx={{
                                                                width: "90%",
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#5E5E5E', // Default border color
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#5E5E5E', // Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '50px', // Adjust the height here
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    padding: '0', // Remove padding inside the input
                                                                    textAlign: 'center', // Center the text (including placeholder)
                                                                    height: '100%', // Ensure the input takes the full height of its container
                                                                },
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    height: '50px',
                                                                    color: "#5E5E5E",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    User Limit  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <TextField
                                                    value={UL}
                                                    onChange={handlechangeUL}
                                                    placeholder="1 or 2 etc."
                                                    error={!!ULError}
                                                    helperText={ULError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Bill Number Prefix Type :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={BNPT}
                                                    onChange={handlechangeBNPT}
                                                    placeholder="BMX"
                                                    error={!!BNPTError}
                                                    helperText={BNPTError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Chef Limit  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <TextField
                                                    value={CL}
                                                    onChange={handlechangeCL}
                                                    placeholder="1 or 2 etc."
                                                    error={!!CLError}
                                                    helperText={CLError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Bill Number Prefix :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={BNP}
                                                    onChange={handlechangeBNP}
                                                    placeholder="BMX"
                                                    error={!!BNPError}
                                                    helperText={BNPError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Bartender Limit  :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <TextField
                                                    value={BL}
                                                    onChange={handlechangeBL}
                                                    placeholder="1 or 2 etc."
                                                    error={!!BLError}
                                                    helperText={BLError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Bill Number :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={BN}
                                                    onChange={handlechangeBN}
                                                    placeholder="100"
                                                    error={!!BNError}
                                                    helperText={BNError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />

                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'0.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3.5}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Order Number :
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    value={ON}
                                                    onChange={handlechangeON}
                                                    placeholder="100"
                                                    error={!!ONError}
                                                    helperText={ONError}
                                                    sx={{
                                                        width: "90%",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#5E5E5E', // Default border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#5E5E5E', // Border color on hover
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#5E5E5E', // Border color when focused
                                                            },
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '50px', // Adjust the height here
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            padding: '0', // Remove padding inside the input
                                                            textAlign: 'center', // Center the text (including placeholder)
                                                            height: '100%', // Ensure the input takes the full height of its container
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            height: '50px',
                                                            color: "#5E5E5E",
                                                        },
                                                    }}
                                                />

                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={6} sx={{marginBottom:'0.5%',marginLeft:'1.5%'}}>
                                    <FormGroup>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={checked.checkbox1}
                                                    onChange={handleChange}
                                                    name="checkbox1"
                                                />
                                                }
                                                label="Reset Order No Daily"
                                            />
                                            </Grid>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={checked.checkbox2}
                                                    onChange={handleChange}
                                                    name="checkbox2"
                                                />
                                                }
                                                label="Reset Order No Monthly"
                                            />
                                            </Grid>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={checked.checkbox3}
                                                    onChange={handleChange}
                                                    name="checkbox3"
                                                />
                                                }
                                                label="Reset Bill No Yearly"
                                            />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>              
                                </Grid>
                                <Grid item xs={12} sx={{display:"flex",justifyContent:"flex-end",marginRight:"3.5%",marginBottom:"1.25%",marginTop:"0.5%"}}>
                                <Button
                                    variant="contained"
                                    style={{
                                    borderRadius: "10px", // Adjust the border radius as needed
                                    fontFamily: "Poppins", // Set the font family
                                    fontSize: "18px", // Adjust the font size as needed
                                    backgroundColor: "#058E6E",
                                    textAlign: "center" ,
                                    width:"20%"// Set the background color
                                    }}
                                    onClick={()=>save_next_data()}
                                >
                                    Save and Next
                                </Button>
                            </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display: DisplayData === 'feature'? 'show':'none'}}>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <Box sx={{ color: '#5E5E5E', p: 1, border: '2px solid #5E5E5E', borderRadius: '5px',display:'flex',justifyContent:'center',alignItems:'center',fontFamily: "Poppins" }}>
                                                    FEATURES
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ color: '#FFFFFF', p: 1, border: '2px solid #058E6E',backgroundColor:'#058E6E',borderRadius: '5px',display:'flex',justifyContent:'center',alignItems:'center',fontFamily: "Poppins" }}>
                                                            YES
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ color: '#FFFFFF', p: 1, border: '2px solid #8E0515',backgroundColor:'#8E0515',borderRadius: '5px',display:'flex',justifyContent:'center',alignItems:'center',fontFamily: "Poppins" }}>
                                                            NO
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Analytics
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Grid container spacing={1} >
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Analytics.yes}
                                                                onChange={handleChangeAnalytics}
                                                                name="yes"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Analytics.no}
                                                                onChange={handleChangeAnalytics}
                                                                name="no"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Inventory
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Inventory.yes}
                                                                onChange={handleChangeInventory}
                                                                name="yes"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Inventory.no}
                                                                onChange={handleChangeInventory}
                                                                name="no"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                   Live Updates
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={LiveUpdate.yes}
                                                                onChange={handleChangeLiveUpdate}
                                                                name="yes"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={LiveUpdate.no}
                                                                onChange={handleChangeLiveUpdate}
                                                                name="no"
                                                                sx={checkboxStyles}
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    PBO
                                                    (Payment Before Order)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={PBO.yes}
                                                                onChange={handleChangePBO}
                                                                sx={checkboxStyles}
                                                                name="yes"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={PBO.no}
                                                                onChange={handleChangePBO}
                                                                sx={checkboxStyles}
                                                                name="no"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    BAR
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Bar.yes}
                                                                onChange={handleChangeBar}
                                                                sx={checkboxStyles}
                                                                name="yes"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={Bar.no}
                                                                onChange={handleChangeBar}
                                                                sx={checkboxStyles}
                                                                name="no"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Employee Management
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={EmployeeManagement.yes}
                                                                onChange={handleChangeEmployeeManagement}
                                                                sx={checkboxStyles}
                                                                name="yes"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={EmployeeManagement.no}
                                                                onChange={handleChangeEmployeeManagement}
                                                                sx={checkboxStyles}
                                                                name="no"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    QR Model
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={QrModel.yes}
                                                                onChange={handleChangeQrModel}
                                                                sx={checkboxStyles}
                                                                name="yes"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={QrModel.no}
                                                                onChange={handleChangeQrModel}
                                                                sx={checkboxStyles}
                                                                name="no"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{marginBottom:'1.5%',marginLeft:'3.5%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Typography
                                                    sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '500',
                                                            color: '#2E2E2E',
                                                            marginLeft: "4.5%",
                                                            paddingTop: "1.5%"
                                                        }}
                                                        variant="h5"
                                                        noWrap
                                                        component="div"
                                                >
                                                    Active Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={ActiveStatus.yes}
                                                                onChange={handleChangeProductStatus}
                                                                sx={checkboxStyles}
                                                                name="yes"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                checked={ActiveStatus.no}
                                                                onChange={handleChangeProductStatus}
                                                                sx={checkboxStyles}
                                                                name="no"
                                                            />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{display:"flex",justifyContent:"center",marginTop:"2.5%",marginBottom:"2.5%"}}>
                                <Button
                                    variant="contained"
                                    style={{
                                    borderRadius: "10px", // Adjust the border radius as needed
                                    fontFamily: "Poppins", // Set the font family
                                    fontSize: "18px", // Adjust the font size as needed
                                    backgroundColor: "#0093FB",
                                    textAlign: "center" ,
                                    width:"20%"// Set the background color
                                    }}
                                    onClick={()=>save_Product()}
                                >
                                    Save Product
                                </Button>
                            </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    </>
)
}

export default ProductForm;