import React, { forwardRef } from "react";
import './PrintDialog.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PrintDialog = forwardRef((props, ref) => {
  const {
    openPopupBill,
    handlePopupClose,
    Transition,
    pdfUrl,
    convertDateFormat,
    formatTime,
    renderItems,
  } = props;

  return (
    <Dialog
      open={openPopupBill}
      onClose={handlePopupClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          width: "50rem",
        },
      }}
    >
      <DialogTitle>
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: 0, left: 0 }}></div>
          <IconButton
            aria-label="close"
            onClick={handlePopupClose}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="dialog-content-1">
        {pdfUrl && (
          <>
            <div className="bill-container" ref={ref}>
              <h2>{pdfUrl.name}</h2>
              {pdfUrl?.sgst && Number(pdfUrl?.sgst?.toFixed(2)) > 0 ? (
                <>
                  <p>{pdfUrl.address}</p>
                  <p><b>FSSAI:</b> {pdfUrl?.fssai}</p>
                  <p><b>GSTIN:</b> {pdfUrl?.gstin}</p>
                </>
              ) : null}
              <hr />
              <p>
                <b>Date:</b> {convertDateFormat(pdfUrl.date)} <b>Time:</b>{" "}
                {formatTime(pdfUrl.time)}
              </p>
              <hr />
              <p>Order No.: {pdfUrl.order_no}</p>
              <p>Bill No.: {pdfUrl.bill_no}</p>
              <p>Table No: {pdfUrl.table_no}</p>
              <p>Payment Method: {pdfUrl.payment_method}</p>
              <hr />
              <table>
                <thead>
                  <tr>
                    <th>Menu-Item</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                {pdfUrl?.sgst && Number(pdfUrl?.sgst?.toFixed(2)) > 0 ? (
                <>
                  {renderItems()}
                </>
                ): 
                <>
                    {renderItems()}
                    <tr key={pdfUrl?.total?.toFixed(2)}>
                        <td>Total:</td>
                        <td></td>
                        <td></td>
                        <td>₹{pdfUrl?.total?.toFixed(2)}</td>
                    </tr>
                </>
                }
              </tbody>
              </table>
              {pdfUrl?.sgst && Number(pdfUrl?.sgst?.toFixed(2)) > 0 ? (
                <>
              <hr />
              <p>Sub Total: ₹{pdfUrl?.total?.toFixed(2)}</p>
              <p>SGST @ 2.50%: ₹{pdfUrl?.sgst?.toFixed(2)}</p>
              <p>CGST @ 2.50%: ₹{pdfUrl?.cgst?.toFixed(2)}</p>
              <p>Discount: ₹{pdfUrl?.discount?.toFixed(2)||"0.00"}</p>
              <p>Round Off: ₹{pdfUrl.round_off?.toFixed(2)}</p>
              <p>Total with Tax:  ₹{(pdfUrl?.total_with_tax?.toFixed(2)-(pdfUrl?.discount?.toFixed(2)?pdfUrl?.discount?.toFixed(2):0))}</p>
              </>
              ):null}
              <hr />
              <center>
                <img
                  src={`data:image/png;base64,${pdfUrl?.qr_image}`}
                  alt="QR Code"
                  style={{
                    width: "50%",
                  }}
                />
              </center>
              <p className="thank-you">----Thank You Visit Again----</p>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default PrintDialog;
