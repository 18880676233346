import { Box, Button, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../components/Navbar";
import UserDetails from "../../components/UserDetails";
import { useNavigate } from "react-router-dom";
import { CategoryList, EditCategoriesName } from "./EditMenuActions/EditMenuApi";
import { TableData } from "../Reserve/ReserveActions/ReserveActionApi";
import { OrderHistoryData, menudata } from "../Order/OrderActions/OrderActionApi";
import { kothistory } from "../KotHistory/KotHistoryActions/KotHistoryActionApi";
import MessageBox from "../../components/MessageBox";
import loader from '../../assets/EditLoader.gif';
import { get_users_data } from "../EditUsers/EditUsersActions/EditUserActions";
import { socket_url } from "../../Redux/constants";


const EditCategories =()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = sessionStorage?.getItem("Token");
    const product_id = sessionStorage?.getItem("ProductId");
    const email = sessionStorage?.getItem("Email");
    const role = sessionStorage?.getItem("Role");
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [Category,setCategory]=useState('');
    const [CategoryList1,setCategoryList1]=useState();
    const [CategoryType,setCategoryType]=useState('food_and_beverages');
    const [UpdateCategoryType,setUpdateCategoryType]=useState('food_and_beverages');
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const [CategoryItemData,setCategoryItemData] = useState([]);

    const ListCategory = useSelector((state) => state.editmenu.ListResponseData);
    const loadingData = useSelector((state) => state.editmenu.EditCateoriesNameLoading);
    const MainData = useSelector((state)=> state.order.MainMenuResponseData);
    
    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };
    
    const handleCloseMessageBox = () => {
        setShowMessageBox(false);
      };
    
      useEffect(()=>{
        if(!token ||token === "undefined" || token === "null" || token === "none")
          {
              navigate("/")
          }
          else
          {
            if(role !== "manager")
            {
              navigate("/reserve")
            }
            dispatch(CategoryList(email,product_id,token));
            if(ListCategory.length === 0)
              {
                dispatch(get_users_data(email,product_id,token));
                dispatch(TableData(product_id, email, token));
                dispatch(menudata(product_id,email,token));
                dispatch(OrderHistoryData(email, product_id, token));
                dispatch(kothistory(email, product_id, token));
              } 
          }
                // Initialize WebSocket connection
        const socket = new WebSocket(socket_url);
    
        socket.onopen = () => {
         // console.log("WebSocket connection established");
        };
    
        socket.onmessage = (event) => {
          try {
            const message = event.data;
          // Handle incoming messages and update state as needed
          if (message === "edit_category" || message === "edit_menu") {
            dispatch(CategoryList(email,product_id,token)); // Update KOT history on new message
           
          }
        }
        catch (error) {
          console.error("Error parsing message:", error);
        }
      }
      },[dispatch])

      useEffect(() => {
        if (MainData && Category) {
            const data = Object.keys(MainData).flatMap((type)=>
                Object.keys(MainData[type])
                  .flatMap((item) =>
                    MainData[type][item][Category]
                      ? Object.keys(MainData[type][item][Category]).map((item1) => ({
                          id: item1,
                          name: MainData[type][item][Category][item1]?.item_name,
                          ingredients: MainData[type][item][Category][item1]?.item_ingredients,
                          description: MainData[type][item][Category][item1]?.item_description,
                          image: MainData[type][item][Category][item1]?.item_image,
                          prep_time: MainData[type][item][Category][item1]?.item_prep_time_minutes,
                          veg_or_nonveg: item,
                          food_or_beverage: type,
                          amount: MainData[type][item][Category][item1]?.amount,
                        }))
                      : []
                      )
            );
          setCategoryItemData(data);
        }
      }, [MainData, Category]);

      /* useEffect(()=>{
        console.log("array",ListCategory[0]?.[CategoryType])
        if(CategoryType==="food_and_beverages")
        {
            setCategory(ListCategory[0]?.[CategoryType])
        }
        else
        {
            setCategory(ListCategory[1]?.[CategoryType])
        }
        
      },[ListCategory,CategoryType]) */

      useEffect(()=>{
        if(UpdateCategoryType==="food_and_beverages")
        {
           setCategory(ListCategory?.[0]?.[UpdateCategoryType][0])
            setCategoryList1(ListCategory?.[0]?.[UpdateCategoryType])
        }
        else
        {
            setCategory(ListCategory?.[1]?.[UpdateCategoryType]?.[0])
            setCategoryList1(ListCategory?.[1]?.[UpdateCategoryType])
        }  
      },[ListCategory,UpdateCategoryType])

      const handleInputChange = (event) => {
        const list = event.target.value.toLowerCase();
        setInputValue(event.target.value);
        if(CategoryType==="food_and_beverages")
        {
            if (ListCategory?.[0]?.[CategoryType]?.includes(list)) {
                setError('Categorie already exists');
                event.target.focus();
                return;
              }
              else{
                setError('');
                return;
              }
        }
        else
        {
            if (ListCategory?.[1]?.[CategoryType]?.includes(list)) {
                setError('Categorie already exists');
                event.target.focus();
                return;
              }
              else{
                setError('');
                return;
              } 
        }
        
      };

      const handleChange1 = (event) => {
        setCategory(event.target.value);
      };
      const handleCategoryType = (event)=>{
        setCategoryType(event.target.value);
      }
      const handleUpdateCategoryType = (event)=>{
        setUpdateCategoryType(event.target.value);
      }

      const Menupage=()=>{
        navigate(`/EditMenuData?Category=${Category}&CategoryType=${UpdateCategoryType}`)
      }

      const handleSavedata = async()=>{
        if(Category !== '')
        {
            if(error==='')
                {
                    await dispatch(EditCategoriesName(email,product_id,token,inputValue.toLocaleLowerCase(),CategoryType,"add"));
                    setInputValue('');
                }
                else
                {
                    setMessage("Categorie already exists");
                    setMessageType('error');
                    setShowMessageBox(true);
                }
        }
        else
        {
            setError('Categorie Name is Empty');
            return; 
        }
      }

      const handleDeletedata = async()=>{
            if(CategoryItemData && CategoryItemData.length>0)
            {
                setMessage("Categorie has item in it");
                setMessageType('error');
                setShowMessageBox(true);
                return;
            }
            await dispatch(EditCategoriesName(email,product_id,token,Category,UpdateCategoryType,"delete"));
      }

    return(
        <>
        <Grid container spacing={1} sx={{filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto"}}>
            {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="editMenu" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"auto"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{marginTop:"3.5%"}}>
                        <Paper elevation={3}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",marginTop:"1.5%"}}>
                                    <Typography
                                        sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        color: '#5E5E5E',
                                        marginLeft: "1.5%",
                                        paddingTop: "1.5%"
                                        }}
                                        variant="h5"
                                        noWrap
                                        component="div"
                                    >
                                        Add New Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",marginTop:"1.5%"}}>
                                    <Grid item xs={6} sx={{display:"flex",textAlign:"center",justifyContent:"right",alignItems:"right"}}>
                                    <FormControl fullWidth sx={{ fontSize: '1rem',textAlign:"center",justifyContent:"right",alignItems:"right"}}>
                                            <Select
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                value={CategoryType}
                                                onChange={handleCategoryType}
                                                sx={{  height: '3.5rem',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"40%",textAlign:"center",marginLeft:"60.5%"}}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 140,  // Adjust as needed
                                                            //width: '20ch',   // Adjust as needed
                                                            zIndex: 1302,    // Ensure this is higher than the parent element
                                                        },
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    },
                                                }}
                                            >
                                              <MenuItem value="food_and_beverages" sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key="food_and_beverages">
                                                    Food And Beverages
                                                </MenuItem>
                                                {parseInt(sessionStorage.getItem("bartender_limit"))>0?(
                                                    <MenuItem value="alcoholic_beverages" sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key="alcoholic_beverages">
                                                    Alcoholic Beverages
                                                    </MenuItem>
                                                ):null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sx={{display:"flex",textAlign:"center",justifyContent:"left",alignItems:"left",paddingLeft:"1.5%"}}>
                                        <TextField
                                            placeholder="Category Name"
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            error={!!error}
                                            helperText={error}
                                            sx={{
                                                width:"40%",
                                                textAlign: "center",
                                                justifyContent:"center",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent:"center",marginTop:"1.5%",marginBottom:"3.5%"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            borderRadius: 5, // Adjust the border radius as needed
                                            fontFamily: "Poppins", // Set the font family
                                            fontSize: "18px", // Adjust the font size as needed
                                            marginRight:"1.5%",
                                            backgroundColor: "#0093FB",
                                            textAlign: "center" ,
                                            width:"10rem"// Set the background color
                                        }}
                                        onClick={()=>handleSavedata()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{marginTop:"2.5%"}}>
                         <Paper elevation={3}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",marginTop:"1.5%"}}>
                                    <Typography
                                        sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        color: '#5E5E5E',
                                        marginLeft: "1.5%",
                                        paddingTop: "1.5%"
                                        }}
                                        variant="h5"
                                        noWrap
                                        component="div"
                                    >
                                        Select Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",marginTop:"1.5%"}}>
                                    <Grid item xs={6} sx={{display:"flex",textAlign:"center",justifyContent: "flex-end",alignItems:"center"}}>
                                        <FormControl fullWidth sx={{ display:"flex",fontSize: '1rem',textAlign:"center",justifyContent: "flex-end",alignItems:"center"}}>
                                            <Select
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                value={UpdateCategoryType}
                                                onChange={handleUpdateCategoryType}
                                                sx={{  height: '3.5rem',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"40%",textAlign:"center",marginLeft:"60.5%"}}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 140,  // Adjust as needed
                                                            //width: '20ch',   // Adjust as needed
                                                            zIndex: 1302,    // Ensure this is higher than the parent element
                                                        },
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    },
                                                }}
                                            >
                                              <MenuItem value="food_and_beverages" sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key="food_and_beverages">
                                                    Food And Beverages
                                                </MenuItem>
                                                {parseInt(sessionStorage.getItem("bartender_limit"))>0?(
                                                    <MenuItem value="alcoholic_beverages" sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key="alcoholic_beverages">
                                                    Alcoholic Beverages
                                                    </MenuItem>
                                                ):null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sx={{display:"flex",textAlign:"center",justifyContent:"left",alignItems:"left",paddingLeft:"1.5%"}}>
                                        <FormControl fullWidth sx={{ fontSize: '1rem',textAlign:"center",justifyContent:"left",alignItems:"left"}}>
                                            <Select
                                                labelId="demo-customized-select-label"
                                                id="demo-customized-select"
                                                value={Category}
                                                onChange={handleChange1}
                                                sx={{ height: '3.5rem',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"40%",textAlign:"center"}}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 140,  // Adjust as needed
                                                            //width: '20ch',   // Adjust as needed
                                                            zIndex: 1302,    // Ensure this is higher than the parent element
                                                        },
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    },
                                                }}
                                            >
                                             {CategoryList1?.map((item)=>(
                                                <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key={item}>
                                                    {toTitleCase(item)}
                                                </MenuItem>
                                                 ))} 
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent:"center",marginTop:"1.5%",marginBottom:"3.5%"}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            borderRadius: 5, // Adjust the border radius as needed
                                            fontFamily: "Poppins", // Set the font family
                                            fontSize: "18px", // Adjust the font size as needed
                                            marginRight:"1.5%",
                                            backgroundColor: "#8E0515",
                                            textAlign: "center" ,
                                            width:"10rem"// Set the background color
                                        }}
                                        onClick={()=>handleDeletedata()}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            borderRadius: 5, // Adjust the border radius as needed
                                            fontFamily: "Poppins", // Set the font family
                                            fontSize: "18px", // Adjust the font size as needed
                                            marginRight:"1.5%",
                                            backgroundColor: "#0093FB",
                                            textAlign: "center" ,
                                            width:"10rem"// Set the background color
                                        }}
                                        onClick={()=>Menupage()}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {showMessageBox && (
                <MessageBox
                    message={message}
                    type={messageType}
                    duration={3000}
                    onClose={handleCloseMessageBox}
                />
            )}
        </Grid>
        </>
    )

}

export default EditCategories;