import { TYPES } from "../BotHistoryType/BotHistoryType";
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";

export const Bothistory = (email,product_id,token) => (dispatch) =>{
    //dispatch(KotHistoryLoading());
    Axios.get(`${base_url}/bot_history?product_id=${product_id}&email=${email}`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
        type: TYPES.BOT_HISTORY_SUCCESS_DATA_MAIN,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BOT_HISTORY_FAIL_DATA_MAIN,
      });
    });
};



//------------------BOT HISTORY LOADING --------------------------

export const BotHistoryLoading = () => (dispatch) => {
    dispatch({
      type: TYPES.BOT_HISTORY_LODING_DATA_MAIN,
    });
  };
  
  //------------------------- BOT HISTORY CLEAR DATA --------------------
  
  export const BotHistoryClearData = () => (dispatch) => {
    dispatch({
      type: TYPES.BOT_HISTORY_CLEAR_DATA_MAIN,
    });
  };

  
export const CompleteBot = (email,product_id,Bot_id,token,cart) => (dispatch) => {
  dispatch(CompleteBotLoading());

  // Define the data object with the parameters
  const data = {
      "email":email,
      "product_id": product_id,
      "kot_id": Bot_id,
      "cart":cart
  };

  Axios.post(`${base_url}/kot_item_status_update`, data,{
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
      .then((response) => {
          dispatch({
              type: TYPES.COMPLETE_BOT_SUCCESS,
              payload: response.data,
          });
      })
      .catch((error) => {
          dispatch({
              type: TYPES.COMPLETE_BOT_FAIL,
          });
      });
};


  //------------------COMPLETE Bot LOADING --------------------------

export const CompleteBotLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPLETE_BOT_LOADING,
  });
};

//------------------------- COMPLETE BOT CLEAR DATA --------------------

export const CompleteBotClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPLETE_BOT_CLEAR,
  });
};





