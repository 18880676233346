import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from '../../../assets/searchproduct.svg';
import loader from '../../../assets/EditLoader.gif';
import Navbar from "../../../components/Navbar";
import { edit_manager_details, get_manager_details, get_product_data } from "./ManageActions/ManageActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MessageBox from "../../../components/MessageBox";
import UserDetails from "../../../components/UserDetails";
import { socket_url } from "../../../Redux/constants";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EditManager = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = sessionStorage?.getItem("Token");
    const product_id = sessionStorage?.getItem("ProductId");
    const email = sessionStorage?.getItem("Email");
    const product = sessionStorage?.getItem("product");
    const role = sessionStorage?.getItem("Role");
    const loadingData = useSelector((state)=>state.manageProduct.ManageResponseDataLoading);
    const product_name= useSelector((state)=>state.manageProduct.GetProductResponseData);
    const[DisplayData,setDisplayData]=useState("editdata")
    const [SelectProduct,setSelectProduct]=useState('Select Product');
    const [SelectProductKey,setSelectProductKey]=useState('');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [Category,setCategory]=useState('');
    const [ws, setWs] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [UserName,setUserName] = useState('');
    const [Name,setName]=useState('');
    const [Password,setPassword]=useState('');
    const [Email,setEmail]=useState('');
    const [PhoneNo,setPhoneNo]=useState('');
    const [ShowPassword,setShowPassword]=useState(false);
    const [Popup,SetPopup]=useState(false);
    const [Type,setType]=useState(false);
    const [UserId,setUserId]=useState('');
    const [error, setError] = useState('');
    const [UserHistoryData, setUserHistoryData] = useState([]);
   
    
    const handleUserName =(event)=>{
      setUserName(event.target.value);
    }
    const handleName = (event)=>{
      setName(event.target.value);
    }
    const handleEmail = (event)=>{
      setEmail(event.target.value);
    }
    const handlePassword = (event)=>{
      setPassword(event.target.value);
    }
    const handlePhoneNo = (event) =>{
      setPhoneNo(event.target.value);
    }
    const handleClickShowPassword = () => {
      setShowPassword(!ShowPassword)
  };

    const handlePopupClose = () =>{
      SetPopup(false);
    }

    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };
    
    const handleCloseMessageBox = () => {
        setShowMessageBox(false);
      };

  const handleRowClick=(item)=>{
    setUserId(item.user_id)
    setUserName(item.username);
    setName(item.name);
    setEmail(item.email);
    setPassword(item.password);
    setPhoneNo(item.mobile);
    setType("Edit");
    SetPopup(true);
  }
  const handleAddUser=()=>{
    setUserId(0);
    setUserName('');
    setName('');
    setEmail('');
    setPassword('');
    setPhoneNo('');
    setType("Add");
    SetPopup(true);
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUserUpdate=(type)=>{
    if(UserId!==''&&UserName!==''&&Password!==''&&Name!==''&&PhoneNo!==''&&Email!==''&&type!=='')
    {
      dispatch(edit_manager_details(email,SelectProductKey,token,UserId,UserName,Password,Name,PhoneNo,Email,type));
      SetPopup(false)
    }
    else{
      setMessage("Field Can't be Empty");
      setMessageType("Error");
      setShowMessageBox(true);
    }
  }
    
      useEffect(()=>{
        if(!token ||token === "undefined" || token === "null" || token === "none")
          {
              navigate("/")
          }
          else
          {
            setDisplayData('editdata');
            if(product_name.length === 0)
            {
                dispatch(get_product_data(email,token))
            }
          }
                // Initialize WebSocket connection
                const socket = new WebSocket(socket_url);
    
                socket.onopen = () => {
                  console.log("WebSocket connection established");
                };
            
                socket.onmessage = async(event) => {
                  try {
                    const message = event.data;
                    // Handle incoming messages and update state as needed
                    if (message === "edit_manager_details") {
                      const managerDetails = await dispatch(get_manager_details(email, sessionStorage.getItem("product_id"), token));
                      setUserHistoryData(managerDetails);
                    }
                  } catch (error) {
                    console.error("Error handling message:", error);
                  }
                };
            
                socket.onerror = (error) => {
                  console.error("WebSocket error:", error);
                };
            
                socket.onclose = (event) => {
                  if (event.wasClean) {
                    console.log(`WebSocket closed cleanly, code=${event.code}, reason=${event.reason}`);
                  } else {
                    console.error('WebSocket connection closed unexpectedly:', event);
                  }
                };
            
                setWs(socket);
            
                return () => {
                  socket.close();
                };
      },[dispatch])

    
    const handlechangeSelectProduct=(event)=>{
        const selectedOrganization = event.target.value;
        const selectedProductIndex = product_name.organization_names.indexOf(selectedOrganization);
        const selectedProductId = product_name.product_ids[selectedProductIndex];
    
        setSelectProduct(selectedOrganization);
        setSelectProductKey(selectedProductId);
        sessionStorage.setItem("product_id",selectedProductId);
    }
    const search=async()=>{
        if(SelectProduct!=='Select Product' && DisplayData ==='editdata')
        {    
            const Manager_details = await dispatch(get_manager_details(email,SelectProductKey,token));
            setUserHistoryData(Manager_details);
            setDisplayData('managedata');
        }
    }
    return(
        <>
            <Grid container spacing={1} sx={{filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto",height:"100vh"}}>
            {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="editmanager" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"99vh"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={11.8} sx={{marginTop:"1.5%",display: DisplayData === 'editdata'? 'show':'none'}}>
                    <Paper elevation={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{marginBottom:"0.5%",marginTop:'1.5%'}}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontWeight: '800',
                                            color: '#2E2E2E',
                                            paddingLeft:"1.5%",
                                        }}
                                            variant="h3"
                                            noWrap
                                            component="div"
                                    >
                                           Manage Manager
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{marginLeft:"1.5%",marginTop:'10%',marginBottom:'20%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{marginBottom:'0.5%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Poppins',
                                                        fontWeight: '500',
                                                        color: '#2E2E2E',
                                                        paddingLeft:"1.5%",
                                                    }}
                                                        variant="h6"
                                                        noWrap
                                                        component="div"
                                                >
                                                        Search Product Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{margin: 1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <FormControl sx={{ fontSize: '1rem',width:'15%'}}>
                                                            <Select
                                                            labelId="demo-customized-select-label"
                                                            id="demo-customized-select"
                                                            value={SelectProduct}
                                                            onChange={handlechangeSelectProduct}
                                                            sx={{
                                                                height: 'auto',
                                                                color: '#5E5E5E',
                                                                fontSize: '1rem',
                                                                fontFamily: 'Poppins',
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                borderColor: '#5E5E5E',
                                                            }}
                                                            >
                                                                <MenuItem value="Select Product">
                                                                    <em>Select Product</em>
                                                                </MenuItem>
                                                                {product_name && product_name?.organization_names?.map((organization, index) => {
                                                                    return (organization !== 'ADMIN' ?(
                                                                    <MenuItem key={product_name?.product_ids[index]} value={organization} sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'Poppins' }}>
                                                                    {organization}
                                                                    </MenuItem>
                                                                ):null)})}
                                                            </Select>
                                                    </FormControl>
                                                    <Box
                                                        sx={{
                                                            cursor: 'pointer',
                                                            display: 'inline-block',
                                                            paddingLeft:'1.5%',
                                                            height: '3rem', // Ensures the Box wraps around the content
                                                            '&:hover': {
                                                                // Add any additional hover styles if needed
                                                            },
                                                        }}
                                                        onClick={() => {
                                                                search();
                                                            }}
                                                    >
                                                        <Search width={'100%'} height={'100%'}/>
                                                    </Box>
                                            </Grid>
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={11.8} sx={{display: DisplayData === 'managedata'? 'show':'none'}}>
                      <Paper elevation={3} sx={{height: '83vh'}}>
                        <Grid item xs={12}>
                          <Typography
                              sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    color: '#2E2E2E',
                                    marginLeft: "4.5%",
                                    paddingTop: "2%"
                                  }}
                                    variant="h4"
                                    noWrap
                                    component="div"
                          >
                              All Managers
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{height:"70%",marginLeft:"1.5%"}}>
                        <div className="table-container">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Username</th>
                                  <th>Name</th>
                                </tr>
                              </thead>
                              <tbody>
                              {UserHistoryData && UserHistoryData.length > 0 ? (
                                  UserHistoryData.map((item) => (
                                    <tr key={item.user_id} onClick={() => handleRowClick(item)}>
                                      <td>{item.username}</td>
                                      <td>{item.name}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="6">No Users data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",justifyContent:"flex-end",marginRight:"1.5%"}}>
                          <Button
                            variant="contained"
                            style={{
                              borderRadius: "12px", // Adjust the border radius as needed
                              fontFamily: "Poppins", // Set the font family
                              fontSize: "18px", // Adjust the font size as needed
                              backgroundColor: "#0093fb",
                              textAlign: "center" ,
                              width:"20%"// Set the background color
                            }}
                            onClick={()=>handleAddUser()}
                          >
                            Add Manager
                          </Button>
                        </Grid>
                      </Paper>
                   </Grid>
            </Grid>
            <Dialog
                  open={Popup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "45rem", // Adjust the width as needed to make it square
                      height: "auto", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <Box sx={{ fontSize: "40px", color: "#5E5E5E"}}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">{Type} Manager</span>
                            </p>
                          </Box>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                            <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                   User Name :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={UserName}
                                                onChange={handleUserName}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                    Name :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Name}
                                                onChange={handleName}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                   paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                    Email :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Email}
                                                onChange={handleEmail}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    
                                            >
                                                    Password :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Password}
                                                type={ShowPassword ? 'text' : 'password'}
                                                onChange={handlePassword}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    
                                            >
                                                    Phone No:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={PhoneNo}
                                                onChange={handlePhoneNo}
                                                type="number"
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{display:"flex",justifyContent: 'right' }}>
                    {Type === 'Edit'?(
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#8E0515",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={()=>handleUserUpdate("delete")}
                    >
                      Delete Manager
                    </Button>):null}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={()=>handleUserUpdate(Type.toLowerCase())}
                    >
                      {Type} Manager
                    </Button>
                  </DialogActions>
                </Dialog>
            {showMessageBox && (
                <MessageBox
                    message={message}
                    type={messageType}
                    duration={3000}
                    onClose={handleCloseMessageBox}
                />
            )}
        </Grid>    
        </>
    )
}

export default EditManager;