import Axios from 'axios';
import { base_url } from '../../../../Redux/constants';
import { ManageTypes } from '../ManageTypes/ManageTypes';

export const AddProduct=(email,product_id,token,organization_name,model_type,subscription_start,subscription_end,address,fssai,gstin,sgst,cgst,post_pay,pre_pay,live_updates,inventory,analytics,qr_url,bill_no_prefix_type,bill_no_prefix,restaurant_open_time,restaurant_closing_time,reset_order_no_daily,reset_order_no_monthly,reset_bill_no,bill_no,current_order_no,country,state, manager_limit, user_limit, chef_limit, bartender_limit, bar, employee_management, qr_model, AStatus)=>{
const data={
    "email":email,
    "organization_name" : organization_name,
    "model_type" : model_type,
    "subscription_start" : subscription_start,
    "subscription_end" : subscription_end,
    "address" : address,
    "fssai" : fssai,
    "gstin" : gstin,
    "sgst" : sgst,
    "cgst" : cgst,
    "post_pay" : post_pay,
    "pre_pay" : pre_pay,
    "live_updates" : live_updates,
    "inventory" : inventory,
    "analytics" : analytics,
    "qr_url" : qr_url,
    "manager_limit" : manager_limit,
    "user_limit" : user_limit,
    "chef_limit" : chef_limit,
    "bartender_limit" : bartender_limit,
    "bar" : bar,
    "employee_management" : employee_management,
    "qr_model" : qr_model,
    "bill_no_prefix_type" : bill_no_prefix_type,
    "bill_no_prefix" : bill_no_prefix,
    "restaurant_open_time" : restaurant_open_time,
    "restaurant_closing_time" : restaurant_closing_time,
    "reset_order_no_daily" : reset_order_no_daily,
    "reset_order_no_monthly" : reset_order_no_monthly,
    "reset_bill_no" : reset_bill_no,
    "bill_no":bill_no,
    "current_order_no":current_order_no,
    "country":country,
    "state":state,
    "subscription_status":AStatus,
    }
    return(dispatch)=>{
        dispatch({
            type: ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA,
        })
        Axios.post(`${base_url}/add_product`,data,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
          .then((response) =>{
            dispatch({
                type: ManageTypes.MANAGE_SUCCESS_ADD_PRODUCT_DATA,
            })
        })
        .catch((error) => {
            console.error("Error fetching menu data:", error);
        });
        }
    
}
export const EditProduct=(email,product_id,token,organization_name,model_type,subscription_start,subscription_end,address,fssai,gstin,sgst,cgst,post_pay,pre_pay,live_updates,inventory,analytics,qr_url,bill_no_prefix_type,bill_no_prefix,restaurant_open_time,restaurant_closing_time,reset_order_no_daily,reset_order_no_monthly,reset_bill_no,bill_no,current_order_no,country,state, manager_limit, user_limit, chef_limit, bartender_limit, bar, employee_management, qr_model, AStatus)=>{
    const data={
        "email":email,
        "product_id":product_id,
        "organization_name" : organization_name,
        "model_type" : model_type,
        "subscription_start" : subscription_start,
        "subscription_end" : subscription_end,
        "address" : address,
        "fssai" : fssai,
        "gstin" : gstin,
        "sgst" : sgst,
        "cgst" : cgst,
        "post_pay" : post_pay,
        "pre_pay" : pre_pay,
        "live_updates" : live_updates,
        "inventory" : inventory,
        "analytics" : analytics,
        "qr_url" : qr_url,
        "manager_limit" : manager_limit,
        "user_limit" : user_limit,
        "chef_limit" : chef_limit,
        "bartender_limit" : bartender_limit,
        "bar" : bar,
        "employee_management" : employee_management,
        "qr_model" : qr_model,
        "bill_no_prefix_type" : bill_no_prefix_type,
        "bill_no_prefix" : bill_no_prefix,
        "restaurant_open_time" : restaurant_open_time,
        "restaurant_closing_time" : restaurant_closing_time,
        "reset_order_no_daily" : reset_order_no_daily,
        "reset_order_no_monthly" : reset_order_no_monthly,
        "reset_bill_no" : reset_bill_no,
        "bill_no":bill_no,
        "current_order_no":current_order_no,
        "country":country,
        "state":state,
        "subscription_status":AStatus,
        }
        return(dispatch)=>{
            dispatch({
                type: ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA,
            })
            Axios.post(`${base_url}/edit_product`,data,{
                headers: {
                  'Authorization': `Bearer ${token}`,
                }
              })
              .then((response) =>{
                dispatch({
                    type: ManageTypes.MANAGE_SUCCESS_ADD_PRODUCT_DATA,
                })
            })
            .catch((error) => {
                console.error("Error fetching menu data:", error);
            });
            }
        
    }
export const get_product_data=(email,token)=>{
    return(dispatch)=>{
        dispatch({
            type: ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA,
        })
      Axios.get(`${base_url}/get_products?email=${email}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
          .then((response) =>{
              dispatch({
                  type: ManageTypes.GET_PRODUCT_SUCCESS_DATA,
                  payload: response.data
              })
          })
  }
}
export const get_product_details=(email,product_id,token)=> async (dispatch)=>{
    try {
        dispatch({
            type: ManageTypes.GET_PRODUCT_LOADING_DATA_DETAILS,
        })
        const response = await Axios.get(`${base_url}/get_product_details?email=${email}&product_id=${product_id}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
        dispatch({
            type: ManageTypes.GET_PRODUCT_SUCCESS_DATA_DETAILS,
            payload: response.data
        })
        
        return response.data;
    }
    catch (error) {
        throw error; // Rethrow the error to handle it in the component
      }
}
export const get_manager_details=(email,product_id,token) =>async (dispatch) =>{
    try
    {
        dispatch({
            type: ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA,
        })
        const response = await Axios.get(`${base_url}/get_manager_details?product_id=${product_id}&email=${email}`,{
        headers: {
            'Authorization': `Bearer ${token}`,
        }
        })
        dispatch({
            type: ManageTypes.EDIT_MANAGER_SUCCESS_DATA_MAIN,
            payload: response.data,
        });
        dispatch({
            type: ManageTypes.MANAGE_SUCCESS_ADD_PRODUCT_DATA,
        });
        return response.data;
    }
    catch (error) {
      dispatch({
        type:ManageTypes.EDIT_MANAGER_FAIL_DATA_MAIN,
      });
    };
}
export const edit_manager_details=(email,product_id,token,user_id,username,password,name,mobile,user_email,type)=>{
    
    const data={
      "email":email,	
      "product_id":product_id,
      "user_id":user_id,
      "username":username,
      "password":password,
      "name":name,
      "mobile":mobile,
      "user_email":user_email,
      "type":type,
      }
      return(dispatch)=>{
        dispatch({
            type: ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA,
        })
        Axios.post(`${base_url}/edit_manager_details`,data,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
          .then((response) =>{
            dispatch({
                type: ManageTypes.MANAGE_SUCCESS_ADD_PRODUCT_DATA,
            })
        })
        .catch((error) => {
            console.error("Error fetching menu data:", error);
        });
        }
  }

