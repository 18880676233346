//export const base_url = "https://dinedost-backend-service.azurewebsites.net/api/v1"
//export const base_url = "https://bistro-backend-prod.azurewebsites.net/api/v1"
//export const socket_url = "wss://bistro-backend-prod.azurewebsites.net/ws"
//export const socket_url = "wss://dinedost-backend-service.azurewebsites.net/ws"
//export const base_url = "http://127.0.0.1:8000/api/v1"
export const base_url = process.env.REACT_APP_BASE_URL+"/api/v1";
export const socket_url = process.env.REACT_APP_SOCKET_URL;



