import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from './ReserveActions/ReserveActionApi';
import { ReserveActionType } from './ReserveType/ReserveActionType';
import { useNavigate } from 'react-router-dom';
import { OrderHistoryData, menudata } from '../Order/OrderActions/OrderActionApi';
import { kothistory } from '../KotHistory/KotHistoryActions/KotHistoryActionApi';
import { CategoryList } from '../EditMenu/EditMenuActions/EditMenuApi';
import { get_users_data } from '../EditUsers/EditUsersActions/EditUserActions';
import { socket_url } from '../../Redux/constants';


export default function FloorSelect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage?.getItem("Token");
  const product_id = sessionStorage?.getItem("ProductId");
  const email = sessionStorage?.getItem("Email");
  const [ws, setWs] = useState(null);
  const [Floor, setFloor] = React.useState('GF');

  const TableDataMain = useSelector((state) => state.reservetable.TableSchemaResponseDataMain);

  const handleChange = (event) => {
    setFloor(event.target.value);
  };
  useEffect(()=>{
    if(!token || token === "undefined" || token === "null" || token === "none")
      {
          navigate("/")
      }
      else
      {
        dispatch(TableData(product_id, email, token));
        if(TableDataMain.length === 0)
          {
            dispatch(menudata(product_id,email,token));
            dispatch(get_users_data(email,product_id,token));
            dispatch(OrderHistoryData(email, product_id, token));
            dispatch(kothistory(email, product_id, token));
            dispatch(CategoryList(email,product_id,token));
          } 
      }
          // Initialize WebSocket connection
    const socket = new WebSocket(socket_url);
    setWs(socket);


    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      try {
        const message = event.data;
      // Handle incoming messages and update state as needed
      if (message === "reserve_table" || message === "edit_table_schema" || message === "transfer_table") {
        dispatch(TableData(product_id, email, token)); // Update KOT history on new message
      }
    }
    catch (error) {
      console.error("Error parsing message:", error);
    }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close(); // Clean up WebSocket connection on unmount
    }
  },[dispatch])
  useEffect(()=>{
    if(TableDataMain)
      {
        sessionStorage.setItem("Floor",Floor);
        dispatch({
          type: ReserveActionType.TABLE_SCHEMA_SUCCESS_DATA,
          payload: TableDataMain[Floor]
        })
      }
  },[TableDataMain,Floor])

  return (
    <Box sx={{ minWidth: 69,marginLeft: 2,marginRight: 10}}>
      <FormControl fullWidth sx={{ fontSize: '1rem' }}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={Floor}
          onChange={handleChange}
          sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}
        >
          {TableDataMain && Object?.keys(TableDataMain).map((item)=>{ 
            return (item !== "highest_table_id" && item !== "missing_table_ids") ? (
              <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key={item}>
                  {item}
              </MenuItem>
          ) : null;})}
        </Select>
      </FormControl>
    </Box>
  );
}
