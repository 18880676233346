import { OrderActionType } from "../OrderType/OrderActionType";
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";

export const menudata = (product_id,email,token) =>(dispatch)=>{
      //  dispatch({ type: OrderActionType.MENU_LODING_DATA_MAIN });
        
        Axios.get(`${base_url}/get_menu?product_id=${product_id}&email=${email}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
            .then((response) => {
              const foodData = response.data['food'] || {};    
                dispatch({
                    type: OrderActionType.MENU_SUCCESS_DATA_MAIN,
                    payload: response.data
                });
                if (foodData['veg']) {
                  dispatch({
                    type: OrderActionType.VEG_MENU_SUCCESS_DATA_MAIN,
                    payload: foodData['veg']
                  });
                }
                if (foodData['nonveg']) {
                  dispatch({
                    type: OrderActionType.NON_VEG_MENU_SUCCESS_DATA_MAIN,
                    payload: foodData['nonveg'],
                  });
                }
            })
            .catch((error) => {
                console.error("Error fetching menu data:", error);
            });
    };

export const kot = (email,product_id,table_id,cart,notes,token)=>{
    const kotCart = Object.fromEntries(
        Object.entries(cart).map(([key, value]) => {
            return [String(key), value];
        })
    );
    const data={
            'email': email,
            'product_id': product_id,
            'table_id': table_id,
            'cart': kotCart ,
            'notes':notes
    }
    return(dispatch)=>{
        Axios.post(`${base_url}/send_kot`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.error("sending kot", error);
            })
    }
}


// --------- ORDER HISTORY -------------------

export const OrderHistoryData = (email,product_id,token) => (dispatch) =>{
    //dispatch(OrderHistoryLoading());
    Axios.get(`${base_url}/order_history?product_id=${product_id}&email=${email}`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
        type: OrderActionType.ORDER_HISTORY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type:OrderActionType.ORDER_HISTORY_FAIL,
      });
    });
};



//------------------ORDER HISTORY LOADING --------------------------

export const OrderHistoryLoading = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_HISTORY_LOADING,
    });
  };
  
  //------------------------- ORDER HISTORY CLEAR DATA --------------------
  
  export const OrderHistoryClearData = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_HISTORY_CLEAR,
    });
  };


  // ------------ ORDER PAYMENT COMPLETE ---------------------
  export const OrderPaymentCompleted = (order_id,email,product_id,token) => (dispatch) => {
    //dispatch(OrderPaymentCompletedLoading());
  
    // Define the data object with the parameters
    const data = {
        "email":email,
        "product_id": product_id,
        "order_id": order_id
    };
  
    Axios.post(`${base_url}/payment_complete`, data,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
        .then((response) => {
            dispatch({
                type: OrderActionType.ORDER_PAYMENT_COMPLETE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: OrderActionType.ORDER_PAYMENT_COMPLETE_FAIL,
            });
        });
  };
  
  
    //------------------ORDER PAYMENT LOADING --------------------------
  
  export const OrderPaymentCompletedLoading = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_PAYMENT_COMPLETE_LOADING,
    });
  };
  
  //------------------------- ORDER PAYMENT CLEAR DATA --------------------
  
  export const OrderPaymentCompletedClearData = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_PAYMENT_COMPLETE_CLEAR_DATA,
    });
  };
  




  // ------------ ORDER  COMPLETE ---------------------
  export const OrderCompleted = (order_id,email,product_id,token) => (dispatch) => {
    //dispatch(OrderCompletedLoading());
  
    // Define the data object with the parameters
    const data = {
        "email":email,
        "product_id": product_id,
        "order_id": order_id
    };
  
    Axios.post(`${base_url}/order_complete`, data,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
        .then((response) => {
            dispatch({
                type: OrderActionType.ORDER_COMPLETE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: OrderActionType.ORDER_COMPLETE_FAIL,
            });
        });
  };
  
  
    //------------------ ORDER COMPLETE LOADING --------------------------
  
  export const OrderCompletedLoading = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_COMPLETE_LOADING,
    });
  };
  
  //------------------------- ORDER COMPLETE CLEAR DATA --------------------
  
  export const OrderCompletedClearData = () => (dispatch) => {
    dispatch({
      type: OrderActionType.ORDER_COMPLETE_CLEAR_DATA,
    });
  };
  
// --------- ORDER PRINT -------------------

export const OrderPrintBill = (order_id, email, product_id, token) => async (dispatch) => {
  try {
    const response = await Axios.get(`${base_url}/get_order_print_details?product_id=${product_id}&email=${email}&order_id=${order_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    dispatch({
      type: OrderActionType.ORDER_PRINT_SUCCESS,
      payload: response.data, // Ensure the response is assigned directly to payload
    });
    return response; // Return the response object
  } catch (error) {
    dispatch({
      type: OrderActionType.ORDER_PRINT_FAIL,
    });
    throw error; // Rethrow the error to handle it in the component
  }
};

export const OrderEditBill = (data,email,product_id,token) => (dispatch) => {
 
  const data1 = {
      "email":email,
      "product_id": product_id,
      "data":data,
  };

  Axios.post(`${base_url}/edit_order_print_details`, data1,{
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
      .then((response) => {
          
      })
      .catch((error) => {
          
      });
};



//------------------ORDER PRINT BILL LOADING --------------------------

export const OrderPrintLoading = () => (dispatch) => {
  dispatch({
    type: OrderActionType.ORDER_PRINT_LOADING,
  });
};

//------------------------- ORDER PRINT BILL CLEAR DATA --------------------

export const OrderPrintClearData = () => (dispatch) => {
  dispatch({
    type: OrderActionType.ORDER_PRINT_CLEAR,
  });
};

//------------------------ COUSTOMER ORDER HISTORY DATA -------------------
export const get_customer_order_details=(productId,email,order_id,token)=> async (dispatch) =>{
  
  try {
      Axios.get(`${base_url}/get_order_details?product_id=${productId}&email=${email}&order_id=${order_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
          type: OrderActionType.ORDER_CUSTOMER_DETAILS_SUCCESS_DATA,
          payload: response.data
      })
  })
  } catch (error) {
    throw error; 
  }
}

export const CurrentCustomerOrder=(productId,email,tableno,token)=>{
  return(dispatch)=>{
    Axios.get(`${base_url}/table_current_order?product_id=${productId}&email=${email}&table_id=${tableno}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then((response) =>{
              sessionStorage.setItem("orderId", response.data['current_order_id']);
              return response.data;
        })
}
}




