import React, { forwardRef } from "react";
import "./KotDialog.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const KotDialog = forwardRef((props, ref) => {
  const {
    openPopupBill,
    handlePopupClose,
    Transition,
    pdfUrl,
    renderItems,
  } = props;

  return (
    <Dialog
      open={openPopupBill}
      onClose={handlePopupClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          width: "50rem",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle>
        <div style={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handlePopupClose}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="dialog-content-2">
        {pdfUrl && (
          <>
            <div className="bill-container" ref={ref}>
              <h2>KOT Print</h2>
              <hr />
              <p>Order No.: {pdfUrl.order_id}</p>
              <p>Kot No.: {pdfUrl.kot_id}</p>
              <p>Table No: {pdfUrl.table_id}</p>
              <hr />
              <table>
                <thead>
                  <tr>
                    <th>Menu-Item</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                    {renderItems()}
                </tbody>
              </table>
              <hr />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default KotDialog;
