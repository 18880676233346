import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {ReactComponent as HomeLogo} from '../assets/Logout.svg';

import "./UserName.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "./actions";
import MessageUserBox from "./MessageUserBox";
import { socket_url } from "../Redux/constants";

const UserDetails = () =>{
    const dispatch= useDispatch();
    const username = sessionStorage.getItem("Username")
    const navigate = useNavigate();
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [messageQueue, setMessageQueue] = useState([]);
    const [Floor, setFloor] = React.useState('Language');
    const kotComplete = useSelector((state) => state.kotHistory.CompleteKotResponse);

    

    const handleChange = (event) => {
        setFloor(event.target.value);
      };

    const logout= async ()=>{
        dispatch(resetState());
        navigate('/');
        await window.location.reload();
        sessionStorage.clear();
    }
    useEffect(() => {
        if (kotComplete.current_session_id === sessionStorage.getItem("sessionId")) {
          enqueueMessage(`Order is Ready for table no ${kotComplete.table_id}`, 'success');
          kotComplete.current_session_id = 'a';
        }
      }, [kotComplete]);
    
      useEffect(() => {
        const socket = new WebSocket(socket_url);
    
        socket.onopen = () => {
          console.log("WebSocket connection established");
        };
    
        socket.onmessage = (event) => {
          try {
            const message = event.data;
            // Handle incoming messages and update state as needed
            if (message === "kot_item_status_update") {
              if (kotComplete.current_session_id === sessionStorage.getItem("sessionId")) {
                enqueueMessage(`Order is Ready for table no ${kotComplete.table_id}`, 'success');
              }
            }
          } catch (error) {
            console.error("Error parsing message:", error);
          }
        };
    
        return () => {
          socket.close();
        };
      }, []);
    
      const enqueueMessage = (msg, type) => {
        setMessageQueue(prevQueue => {
          const newQueue = [...prevQueue, { message: msg, type }];
          if (!showMessageBox) {
            displayNextMessage(newQueue);
          }
          return newQueue;
        });
      };
    
      const displayNextMessage = (queue) => {
        if (queue.length > 0) {
          const nextMessage = queue[0];
          setCurrentMessage(nextMessage);
          setShowMessageBox(true);
          setMessageQueue(queue.slice(1));
        }
      };
    
      const handleCloseMessageBox = () => {
        setShowMessageBox(false);
        if (messageQueue.length > 0) {
          displayNextMessage(messageQueue);
        }
      };

    return(
        <>
        <Grid container spacing={1} sx={{zIndex:"1"}}>
            <Grid item xs={6} md={6} lg={6} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-start"}}>
            <Typography
                sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        color: '#2E2E2E',
                        textAlign:'center',
                        fontSize:"30px",
                    }}
                    component="div"
                    className="username"
            >
                Hello, {username}
            </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-end"}}>
                <Box sx={{ minWidth: 69,marginRight: 5}} className="language">
                    <FormControl fullWidth sx={{ fontSize: '1rem' }} className="size">
                        <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={Floor}
                        onChange={handleChange}
                        sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}
                        className="size"
                        >
                            <MenuItem value="Language" sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}className="size">Language</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <HomeLogo className="logo" onClick={()=>logout()}/>
            </Grid>
            {showMessageBox && currentMessage && (
                <MessageUserBox
                message={currentMessage.message}
                type={currentMessage.type}
                onClose={handleCloseMessageBox}
                />
            )}
        </Grid>
        </>
    );

}
export default UserDetails;